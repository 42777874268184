import { db } from "config/firebase";
import { toast } from "react-toastify";
import { AUTH_SET_LOADING, LOGIN, LOGOUT, UPDATE_USER } from "../types";

import {
  createUserWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

const auth = getAuth();

// -----------SIGNUP--------------
export const signUp =
  (
    {
      fullname,
      email,
      companyName,
      customerNumber,
      phoneNumber,
      address,
      password,
      notifications,
      videosInprogress,
      videosCompleted,
      userType,
      request,
      payment,
    },
    onSuccess
  ) =>
  async (dispatch) => {
    try {
      dispatch(authLoader(true));
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const { uid } = user;
      await setDoc(doc(db, "users", uid), {
        fullname,
        email,
        companyName,
        customerNumber,
        phoneNumber,
        address,
        notifications,
        videosInprogress,
        videosCompleted,
        userType,
        uid,
        request,
        payment,
      });
      onSuccess();
      toast.success("Gehen Sie zur Verifizierung zu Ihrem E-Mail-Konto", {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(authLoader(false));
    } catch (error) {
      console.log("error: ", error);
      if (error.code === "auth/email-already-in-use") {
        toast.error("E-Mail existiert bereits", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Registrierung fehlgeschlagen: " + error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch(authLoader(false));
    }
  };

// -----------LOGIN----------------
export const login =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch(authLoader(true));
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      const { uid } = user;

      if (uid) {
        const usersCollection = collection(db, "users");
        const userQuery = query(usersCollection, where("uid", "==", uid));
        const userSnapshot = await getDocs(userQuery);
        const userData = userSnapshot.docs[0].data();

        if (userData?.request === "pending") {
          toast.error(
            "Anmeldung fehlgeschlagen. Ihre Anfrage ist in Bearbeitung.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        } else {
          dispatch(refreshAuth(uid));
          toast.success("Anmeldung erfolgreich", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        toast.error("Anmeldung fehlgeschlagen, Benutzer nicht verfügbar", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      dispatch(authLoader(false));
    } catch (error) {
      console.log(error.code);
      if (error.code === "auth/invalid-login-credentials") {
        toast.error("Ungültige Login-Details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Anmeldung fehlgeschlagen: " + error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      dispatch(authLoader(false));
    }
  };

export const refreshAuth = (uid) => async (dispatch) => {
  console.log("uid: ", uid);
  try {
    const userDocRef = doc(db, "users", uid);
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      // console.log("userAction", docSnap.data());
      dispatch({
        type: LOGIN,
        payload: { id: docSnap.id, ...docSnap.data() },
      });
    }
  } catch (error) {
    console.log("error: ", error);
  }
};

// ----------Update User-------------------

export const updateUser =
  (
    {
      uid,
      name,
      newPassword,
      companyName,
      customerNumber,
      phoneNumber,
      address,
      profileImageFile,
    },
    onSuccess = () => {}
  ) =>
  async (dispatch) => {
    try {
      const auth = getAuth();
      const storage = getStorage();
      const db = getFirestore();

      toast.info("Profil aktualisieren...", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: true,
        hideProgressBar: false,
      });

      if (newPassword !== "") {
        await updatePassword(auth.currentUser, newPassword);
      }
      let imgURL;

      if (profileImageFile !== null) {
        const customImgKeyRef = doc(collection(db, "files"));
        const customImgKey = await customImgKeyRef;
        const imgName = profileImageFile.name;
        const ext = imgName?.slice(imgName.lastIndexOf("."));
        const imgRef = ref(
          storage,
          `product_imgs/${customImgKey.id}${ext.toLowerCase()}`
        );
        await uploadBytes(imgRef, profileImageFile);
        imgURL = await getDownloadURL(imgRef);
        console.log("imgURL: ", imgURL);
      }
      const userDocRef = doc(db, "users", uid);

      const userDataToUpdate = {
        fullname: name,
        companyName: companyName,
        customerNumber: customerNumber,
        phoneNumber: phoneNumber,
        address: address,
      };

      if (imgURL !== undefined) {
        userDataToUpdate.profileImage = imgURL;
      }

      await updateDoc(userDocRef, userDataToUpdate);
      const dispatchPayload = {
        type: UPDATE_USER,
        payload: {
          uid,
          name,
          newPassword,
          companyName,
          customerNumber,
          phoneNumber,
          address,
        },
      };
      if (imgURL !== undefined) {
        dispatchPayload.payload.profileImage = imgURL;
      }
      dispatch(dispatchPayload);
      toast.success("Profil erfolgreich aktualisiert", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1500,
      });
      onSuccess();
    } catch (error) {
      console.log(error);

      if (error.code === "auth/requires-recent-login") {
        toast.error(
          "Sie können Ihr Passwort nur innerhalb von fünf Minuten nach der Anmeldung ändern.",
          { position: toast.POSITION.TOP_RIGHT, autoClose: 3000 }
        );
      } else {
        toast.error(
          "Fehler beim Aktualisieren des Profils. Bitte versuche es erneut.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1500,
          }
        );
      }
    }
  };

export const forgetAction =
  (email, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      await sendPasswordResetEmail(auth, email).then(onSuccess());
    } catch (error) {
      const errorMessage = error.message;
      toast.error(errorMessage);
    }
  };

export const getEditorId = () => async (dispatch) => {
  const q = query(collection(db, "users"), where("userType", "==", "editor"));
  const querySnapshot = await getDocs(q);
  if (!querySnapshot.empty) {
    const firstDoc = querySnapshot.docs[0];
    return firstDoc.id;
  } else {
    console.log("No documents found.");
  }
};

// ------------LOGOUT--------------

export const logout = () => async (dispatch) => {
  dispatch({
    type: LOGOUT,
  });
};
// export const logout = () => async (dispatch) => {
//   try {
//     await signOut(auth);
//     dispatch({
//       type: LOGOUT,
//     });
//     localStorage.removeItem("auth");
//   } catch (error) {
//     console.error("Sign-out failed:", error);
//   }
// };

// -------------Loader----------------
export const authLoader = (value) => async (dispatch) => {
  // console.log(">>value: ", value);
  dispatch({
    type: AUTH_SET_LOADING,
    payload: value,
  });
};
