import { AUTH_SET_LOADING, LOGIN, LOGOUT, UPDATE_USER } from "../types";

const initialState = {
  user: null,
  uid: null,
  loading: false,
};

export default function authUserReducer(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN:
      return {
        ...state,
        uid: payload.id,
        user: { ...payload },
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
        uid: null,
      };
      case AUTH_SET_LOADING:
      return {
        ...state,
        loading: payload,
      };
      case UPDATE_USER:
        return {
          ...state, 
          uid: payload.uid, 
          user: { ...state.user, ...payload },
        };
    default:
      return { ...state };
  }
}
