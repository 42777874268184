import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { forgetAction } from "store/actions/authAction";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      forgetAction(email, () => {
        toast.success(
          "Bitte überprüfen Sie Ihre E-Mails, um das Passwort zurückzusetzen",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setTimeout(() => {
          history.push("/auth/login");
        }, 3000);
      })
    );
  };
  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className=" mb-4">
              <small>
                Geben Sie die mit Ihrem Konto verknüpfte E-Mail-Adresse ein und
                wir senden Ihnen einen Link zum Zurücksetzen Ihres Passworts
              </small>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="email"
                    autoComplete="new-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="success"
                  type="submit"
                  // onClick={() =>
                  //   dispatch(
                  //     forgetAction(email, () => {
                  //       history.push("/auth/login");
                  //       toast.success(
                  //         "Bitte überprüfen Sie Ihre E-Mails, um das Passwort zurückzusetzen",
                  //         {
                  //           position: toast.POSITION.TOP_RIGHT,
                  //         }
                  //       );
                  //     })
                  //   )
                  // }
                >
                  Continue
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ForgetPassword;
