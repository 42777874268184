import { combineReducers } from "redux";
import { notificationsReducer } from "./addNotificationsReducer";
import addProjectReducer from "./addProjectReducer";
import authManageReducer from "./authManage";
import authUserReducer from "./authUser";
import projectDetailsReducer from "./projectDetailsReducer";
import projectReducer from "./projectReducer";
import singleProjectReducer from "./singleProjectReducer";
import customerReducer from "./customerReducer";

export let rootReducer = combineReducers({
  authUser: authUserReducer,
  authManage: authManageReducer,
  addProject: addProjectReducer,
  videoSubmission: projectDetailsReducer,
  project: projectReducer,
  notifications: notificationsReducer,
  singleProject: singleProjectReducer,
  usersData: customerReducer,
});

export default rootReducer;
