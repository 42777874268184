/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "store/actions/authAction";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import HeadingCard from "components/HeadingCard";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.authUser);

  const initialState = {
    fullname: "",
    email: "",
    password: "",
  };

  const [loginData, setLoginData] = useState(initialState);

  const handleChange = (event) => {
    const { name, value } = event.target;

    setLoginData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(login(loginData));
    setLoginData(initialState);
  };

  return (
    <>
      <Col lg="5" md="7">
        <div className="mb--4">
          <HeadingCard title="LOG IN" />
        </div>
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3 mt-5">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    name="email"
                    type="email"
                    autoComplete="new-email"
                    onChange={handleChange}
                    value={loginData.email}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    onChange={handleChange}
                    value={loginData.password}
                    required
                  />
                </InputGroup>
              </FormGroup>
              <Link className="text-muted" to="/auth/forget-password">
                <small>Passwort vergessen?</small>
              </Link>
              <div className="text-center">
                <Button className="my-4" color="success" disabled={loading}>
                  {loading ? <Spinner /> : "Sign in"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <Link className="text-light" to="/auth/forget-password">
              <small>Passwort vergessen?</small>
            </Link>
          </Col>
        </Row> */}
        <ToastContainer autoClose={500} />
      </Col>
    </>
  );
};

export default Login;
