const initialState = {
  projects: [],
  loading: false,
  loader: false,
  error: null,
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_PROJECTS_REQUEST':
      return {
        ...state,
        loading: true,
        error: null,
      };
      
      case 'FETCH_PROJECTS_SUCCESS':
      return {
        ...state,
        projects: action.payload,
        loading: false,
      };
      case 'FETCH_MORE_PROJECTS_SUCCESS':
      return {
        ...state,
        projects: [...state.projects, ...action.payload],
        loading: false,
      };

    case 'FETCH_PROJECTS_FAILURE':
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
      case 'ADD_PROJECT_LOADER':
        return {
          ...state,
          loader: action.payload,
        };
      
    default:
      return state;
  }
};

export default projectReducer;
