import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Modal,
  Progress,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import fetchSingleProject from "store/actions/fetchSingleProject";
import {
  addSubmission,
  getProjectSubmissions,
  updateSubmissionStatus,
} from "store/actions/projectDetailsAction";
import uploadIcon from "../assets/img/brand/upload.svg";
import OnlyHeader from "./Headers/OnlyHeader";
import HeadingCard from "./HeadingCard";
import { FaDownload } from "react-icons/fa";

const ProjectDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  // initialState
  const initialState = {
    status: "pending",
    projectId: id,
    videoFileUrls: [],
    comment: "",
  };

  // All Selectors
  const { videoUploadingProgress } = useSelector(
    (state) => state.videoSubmission
  );
  const { user, uid } = useSelector((state) => state.authUser);
  const { project, singleLoader } = useSelector((state) => state.singleProject);
  const { submission } = useSelector((state) => state.videoSubmission);

  // All States
  const [mySubmissions, setMySubmissions] = useState([]);
  const [modal, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [videoLoader, setVideoLoader] = useState(-1);
  const [customLoader, setCustomLoader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [rejectComment, setRejectComment] = useState(false);
  const [selectedRejectIndex, setSelectedRejectIndex] = useState(-1);
  const [formData, setFormData] = useState(initialState);
  const [selectedVideos, setSelectedVideos] = useState([]);

  // Arrow Functions
  const toggle = () => {
    setModal(!modal), setSelectedVideos([]);
  };

  const handleVideoChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 0) {
      // const reader = new FileReader();
      const previewVideos = files.map((file) => ({
        file: file,
        previewURL: URL.createObjectURL(file),
      }));
      setSelectedVideos((curr) => [...curr, ...previewVideos]);
      setFormData((curr) => ({
        ...curr,
        videoFileUrls: [...curr.videoFileUrls, ...files],
      }));
    } else {
      alert("Invalid file format. Please upload an MP4 video.");
    }
  };

  const handleDownload = async (url, fileName) => {
    setVideoLoader(url);
    try {
      const response = await fetch(
        `https://cors-platform.herokuapp.com/${url}`
      );
      console.log({ response });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", fileName);
      link.click();
      URL.revokeObjectURL(link.href);
      setVideoLoader(-1);
    } catch (error) {
      console.error("Error downloading video:", error);
      setVideoLoader(-1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const uploadToastId = toast.info("Video hochladen...", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
      });

      await dispatch(
        addSubmission(formData, () => {
          setLoader(false);
          setSelectedVideos([]);
        })
      );
      setFormData(initialState);

      toast.dismiss(uploadToastId);
      setModal(false);
      toast.success("Video erfolgreich hinzugefügt!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      history.push("/admin/projects");
    } catch (error) {
      toast.error("Video zur Fehlerübermittlung. Überprüfen Sie bitte das.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleAcceptBtn = async (submissionIds) => {
    try {
      await dispatch(
        updateSubmissionStatus(submissionIds, "approved", formData?.comment)
      );

      toast.success("Einreichung erfolgreich angenommen!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      toast.error(
        "Fehler beim Akzeptieren der Übermittlung. Bitte versuche es erneut.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const handleRejectBtn = async (submissionIds) => {
    try {
      if (formData.comment !== "") {
        await dispatch(
          updateSubmissionStatus(submissionIds, "rejected", formData?.comment)
        );
        toast.success("Einreichung erfolgreich abgelehnt!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setFormData({
          ...formData,
          comment: "",
        });
        setRejectComment(false);
        setSelectedRejectIndex(-1);
      } else {
        toast.error("Bitte geben Sie einen Ablehnungskommentar ein.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      toast.error(
        "Fehler beim Zurückweisen der Übermittlung. Bitte versuche es erneut.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  // Function For Check File Type Image or Video
  function getFileType(url) {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const videoExtensions = ["mp4", "avi", "mov", "mkv"];

    // Extract the file name from the URL
    const fileName = decodeURIComponent(url.split("%2F").pop().split("?")[0]);

    // Extract the file extension from the file name
    const extension = fileName.split(".").pop().toLowerCase();

    if (imageExtensions.includes(extension)) {
      console.log("imageExtensions_Img: ", imageExtensions.includes(extension));
      return "image";
    } else if (videoExtensions.includes(extension)) {
      console.log(
        "videoExtensions_video: ",
        videoExtensions.includes(extension)
      );
      return "video";
    } else {
      // Handle other file types if needed
      return "unknown";
    }
  }

  // UseEffect
  useEffect(() => {
    setUploadProgress(videoUploadingProgress);
  }, [videoUploadingProgress]);

  useEffect(() => {
    dispatch(fetchSingleProject(id, uid));
    dispatch(getProjectSubmissions(id));
  }, [id]);

  useEffect(() => {
    if (project?.videoFileUrls) {
      project.videoFileUrls.map(getFileType);
    }
  }, [project]);

  useEffect(() => {
    if (submission?.length > 0) {
      setMySubmissions(submission);
    } else {
      setMySubmissions([]);
    }
  }, [submission]);

  return (
    <div>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Col className="m-auto" lg="10" md="12">
          <div className="mb--4">
            <HeadingCard title="PROJEKT DETAILS" />
          </div>
          <Card className="bg-secondary shadow border-0 mb-5">
            <CardBody className="px-lg-5 py-lg-5">
              {singleLoader ? (
                <Row className="justify-content-center py-3">
                  <Spinner />
                </Row>
              ) : (
                <>
                  <Row>
                    <Table size="sm" bordered responsive>
                      <tbody>
                        <tr>
                          <th scope="row" className="w-25 text-capitalize">
                            TITLE
                          </th>
                          <td>{project?.title}</td>
                        </tr>
                        {user?.userType === "editor" ||
                          ("admin" && (
                            <>
                              <tr>
                                <th
                                  scope="row"
                                  className="w-25 text-capitalize"
                                >
                                  Kundenname
                                </th>
                                <td>{project?.userDetail?.fullname}</td>
                              </tr>
                              <tr>
                                <th
                                  scope="row"
                                  className="w-25 text-capitalize"
                                >
                                  Kundennummer
                                </th>
                                <td>{project?.userDetail?.customerNumber}</td>
                              </tr>
                            </>
                          ))}
                        <tr>
                          <th scope="row" className="w-25 text-capitalize">
                            PLATFORM
                          </th>
                          <td>{project?.videoPlatform}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="w-25 text-capitalize">
                            LÄNGE
                          </th>
                          <td>{project?.videoLength}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="w-25 text-capitalize">
                            UNTERTITEL
                          </th>
                          <td>{project?.subtitle}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="w-25 text-capitalize">
                            STATUS
                          </th>
                          <td>{project?.status}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="w-25 text-capitalize">
                            DATUM
                          </th>
                          <td>
                            {project?.createdAt && (
                              <span>
                                {moment(project?.createdAt?.toDate()).format(
                                  "DD.MM.YYYY HH:mm:ss"
                                )}
                              </span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="row" className="w-25 text-capitalize">
                            ANMERKUNG
                          </th>
                          <td>{project?.annotation}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="w-25 text-capitalize">
                            MUSIK
                          </th>
                          <td>{project?.music}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="w-25 text-capitalize">
                            PRIORITIERUNG
                          </th>
                          <td>{project?.prioritization}</td>
                        </tr>
                        <tr>
                          <th scope="row" className="w-25 text-capitalize">
                            VIDEOS
                          </th>
                          <td>
                            <div className="d-flex flex-wrap">
                              {project?.videoFileUrls?.map((url, index) => {
                                const fileType = getFileType(url);

                                return (
                                  <>
                                    <div
                                      className="mr-3 mb-2 position-relative"
                                      key={index}
                                    >
                                      {fileType == "image" ? (
                                        <img
                                          src={url}
                                          alt={`Image ${index}`}
                                          className={`rounded custom_widthHeight ${
                                            fileType === "image"
                                              ? "custom_widthHeight_Img"
                                              : ""
                                          }`}
                                        />
                                      ) : (
                                        
                                        <video
                                          className="rounded custom_widthHeight"
                                          controls
                                          muted
                                        >
                                          <source
                                            src={`${url}#t=0.001`}
                                            type="video/mp4"
                                          ></source>
                                        </video>
                                      )}
                                      <div
                                        className={`download ${
                                          fileType == "image"
                                            ? "downloadImg"
                                            : "downloadVideo"
                                        } cursor-pointer mb-2`}
                                      >
                                       {videoLoader == url ? <Spinner size='sm' color="white"/> : <FaDownload className="text-white"  onClick={() =>
                                          handleDownload(
                                            url,
                                            `${
                                              fileType === "video"
                                                ? `video_${index}.mp4`
                                                : `video_${index}.jpg`
                                            }`
                                          )
                                        } />}
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Row>
                  {mySubmissions?.map((url, index) => {
                    console.log({ url });
                    return (
                      <>
                        <div key={index} className="border-2 border-bottom">
                          <Row className="align-items-center my-4">
                            {customLoader ? (
                              <Spinner />
                            ) : (
                              <div
                                className="position-relative"
                                width="200px"
                                height="160px"
                              >
                                <h4 className="mt-3">
                                  Einreichung des Herausgebers
                                </h4>
                                <video
                                  width="200px"
                                  height="160px"
                                  className="rounded mb-1"
                                  controls
                                  muted
                                >
                                  <source
                                    src={`${url?.videoFileUrls?.[0]}#t=0.001`}
                                    id="video-source"
                                    type="video/mp4"
                                  ></source>
                                </video>
                                <div
                              
                                  className="download downloadVideos cursor-pointer mb-2"
                                >
                                  {videoLoader ==url?.videoFileUrls[0]? <Spinner size='sm' color="white"/> : <FaDownload className="text-white"     onClick={() =>
                                    handleDownload(
                                      url?.videoFileUrls[0],
                                      `video_${index}.mp4`
                                    )
                                  }/>}
                                </div>
                              </div>
                            )}
                            {/* <Col sm={6} className="text-center text-md-right">
                          <small className="mb-0 text-primary text-capitalize">
                            {video?.projectId === id ? video?.status : ""}
                          </small>
                        </Col> */}
                          </Row>

                          <Row className="justify-content-end align-items-center flex-column">
                            <h5>
                              {user?.userType === "editor"
                                ? "Kundenantwort"
                                : "Deine Antwort"}
                            </h5>
                            <small className="mb-0 ">
                              {url?.projectId === id ? url?.comment : ""}
                            </small>
                            <h3
                              className={`${
                                url?.status == "approved"
                                  ? "d-block text-success"
                                  : "d-none"
                              }`}
                            >
                              {user?.userType === "editor"
                                ? "Herzlichen Glückwunsch, Ihr Video wurde genehmigt."
                                : "Du hast das Video genehmigt"}
                            </h3>
                          </Row>
                          {rejectComment && selectedRejectIndex === url?.id && (
                            <FormGroup>
                              <Input
                                type="textarea"
                                className="shadow border"
                                placeholder="Grund schreiben..."
                                bsSize="sm"
                                rows="6"
                                required
                                value={formData.comment}
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    comment: e.target.value,
                                  })
                                }
                              />
                            </FormGroup>
                          )}

                          <Row className="justify-content-center my-3">
                            {user?.userType === "customer" ||
                            user?.userType === "admin" ? (
                              <>
                                {rejectComment ? (
                                  <>
                                    {" "}
                                    <Button
                                      color="success"
                                      className={`${
                                        url?.status !== "pending"
                                          ? "d-none"
                                          : ""
                                      }`}
                                      onClick={() => handleRejectBtn(url?.id)}
                                    >
                                      Absenden
                                    </Button>
                                    <Button
                                      color="danger"
                                      className={`${
                                        url?.status !== "pending"
                                          ? "d-none"
                                          : ""
                                      }`}
                                      onClick={() => {
                                        setRejectComment(false);
                                        setSelectedRejectIndex(-1);
                                      }}
                                    >
                                      Zurück
                                    </Button>
                                  </>
                                ) : (
                                  <div
                                    className={`${
                                      url?.status !== "pending"
                                        ? "d-none"
                                        : "text-center"
                                    }`}
                                  >
                                    <h2 className="text-danger">
                                      Straight Media wartet auf Ihre Antwort
                                    </h2>
                                    <h5>Der Kunde wartet auf Ihre Antwort</h5>
                                    <div>
                                      <Button
                                        color="success"
                                        onClick={() => handleAcceptBtn(url?.id)}
                                      >
                                        Akzeptieren
                                      </Button>
                                      <Button
                                        color="danger"
                                        onClick={() => {
                                          setRejectComment(true);
                                          setSelectedRejectIndex(url?.id);
                                        }}
                                      >
                                        Revision
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {url?.status === "pending" ? (
                                  "Warten auf die Antwort des Kunden"
                                ) : (
                                  <div
                                    className={`${
                                      url?.allowSubmissions ? "" : "d-none"
                                    }`}
                                  >
                                    <Button color="success" onClick={toggle}>
                                      Vorlage
                                    </Button>
                                  </div>
                                )}
                              </>
                            )}
                          </Row>

                          <Button
                            color="success"
                            target="_blank"
                            href={url?.videoFileUrls[0]}
                            className={`text-center ${
                              (user?.userType === "customer" ||
                                user?.userType === "admin") &&
                              url?.status === "approved"
                                ? "d-block"
                                : "d-none"
                            }`}
                          >
                            Laden Sie das Endprodukt herunter
                          </Button>
                        </div>
                        <div className=" text-center mb-3 mt-5 w-100">
                          {user?.userType === "customer" &&
                            url?.status === "inrevision" && (
                              <h4>Warten auf die Antwort des Herausgebers</h4>
                            )}
                        </div>
                      </>
                    );
                  })}

                  {mySubmissions?.length == 0 && (
                    <div className=" text-center mb-3 mt-5 w-100">
                      {user?.userType === "editor" ? (
                        <div>
                          <h4>Laden Sie Ihre erste Einreichung hoch</h4>

                          <Button
                            color="success"
                            onClick={toggle}
                            className="text-center"
                          >
                            Vorlage
                          </Button>
                        </div>
                      ) : (
                        <h4>Warten auf die Antwort des Herausgebers</h4>
                      )}
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Container>

      {/* Modal -- START */}
      <Modal isOpen={modal} toggle={toggle} centered>
        <Container>
          <Row className="align-items-center">
            <Col
              className={`${
                selectedVideos?.length == 0 ? "d-block" : "d-none"
              }`}
            >
              <Card
                className="shadow mx-auto my-4 rounded"
                style={{ width: "200px", height: "160px" }}
              >
                <label htmlFor="uploadFile" className="d-block m-auto">
                  <img
                    src={uploadIcon}
                    alt="upload"
                    className="d-block my-auto img-fluid "
                    style={{ width: "200px", height: "160px" }}
                  />
                </label>
                <input
                  type="file"
                  accept="video/mp4"
                  id="uploadFile"
                  name="uploadFile"
                  required
                  style={{ display: "none" }}
                  onChange={handleVideoChange}
                />
              </Card>
            </Col>
            {selectedVideos?.map((video, id) => (
              <Col
                key={id}
                className="d-flex flex-column justify-content-center"
              >
                <video
                  width="200px"
                  height="160px"
                  controls
                  className="p-3 d- mx-auto"
                >
                  <source
                    src={`${video?.previewURL}#t=0.001`}
                    id="video-source"
                    type="video/mp4"
                  ></source>
                </video>
                <Progress
                  value={uploadProgress}
                  animated
                  className={`${
                    uploadProgress == 0 || uploadProgress == 100
                      ? "d-none"
                      : "mx-auto"
                  }`}
                  style={{ width: "170px" }}
                  color="success"
                />
              </Col>
            ))}
          </Row>
          <Row className="justify-content-center">
            <Button
              disabled={selectedVideos?.length === 0 || loader}
              color="success"
              size="sm"
              className="my-3"
              onClick={handleSubmit}
            >
              {loader ? <Spinner size="sm" /> : "Video hochladen"}
            </Button>
          </Row>
        </Container>
      </Modal>
      <ToastContainer autoClose={5000} />
      {/* Modal -- END */}
    </div>
  );
};
export default ProjectDetails;
