import OnlyHeader from "components/Headers/OnlyHeader";
import React from "react";
import ProjTable from "components/Tables/ProjTable";
import { Container } from "reactstrap";

const Projects = () => {
  return (
    <div>
      <OnlyHeader />

      <Container className="mt--7" fluid>
        <ProjTable/>
      </Container>
    </div>
  )
};

export default Projects;
