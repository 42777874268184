import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  startAfter,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../config/firebase";

export const fetchAllCustomers = (request) => async (dispatch) => {
  dispatch({
    type: "FETCH_USER_REQUEST",
    payload: null,
  });

  try {
    dispatch(loader(true));
    const fetchLimit = 10;
    const projectsCollection = collection(db, "users");
    const limitedQuery = query(
      projectsCollection,
      where("userType", "==", "customer"),
      where("request", "==", request),
      limit(fetchLimit)
    );

    onSnapshot(limitedQuery, (querySnapshot) => {
      const users = querySnapshot.docs.map((document) => {
        return {
          id: document.id,
          ...document.data(),
        };
      });

      dispatch({
        type: "FETCH_USER_SUCCESS",
        payload: users,
      });

      dispatch(loader(false));
    });
  } catch (error) {
    console.error("Error fetching users:", error);
    dispatch({
      type: "FETCH_USER_FAILURE",
      payload: error.message,
    });
  }
};

export const fetchMoreCustomers =
  (request, lastItem, onError = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: "FETCH_USER_REQUEST",
    });

    try {
      const fetchLimit = 10;
      const projectsCollection = collection(db, "users");

      const lastItemDoc = await getDoc(doc(projectsCollection, lastItem));

      const projectData = query(
        projectsCollection,
        where("userType", "==", "customer"),
        where("request", "==", request),
        startAfter(lastItemDoc),
        limit(fetchLimit)
      );

      const querySnapshot = await getDocs(projectData);
      let users = [];
      querySnapshot.forEach((document) => {
        users.push({
          id: document.id,
          ...document.data(),
        });
      });

      if (users?.length < fetchLimit) {
        onError();
      }

      dispatch({
        type: "FETCH_MORE_USER_SUCCESS",
        payload: users,
      });
    } catch (error) {
      dispatch({
        type: "FETCH_USER_FAILURE",
        payload: error.message,
      });
    }
  };

export const fetchUpdateRequest = (newRequest, uId, customerNumber) => async () => {
  try {
    const userDocRef = doc(db, "users", uId);
    await updateDoc(userDocRef, {request: newRequest, customerNumber: customerNumber});
    console.log("Request updated successfully!");
  } catch (error) {
    console.log("Error Request Update status:", error.message);
  }
};

export const loader = (value) => async (dispatch) => {
  dispatch({
    type: "ADD_USER_LOADER",
    payload: value,
  });
};

export const updatePaymentStatus = (userId, paymentStatus) => async () => {
  try {
    const userDocRef = doc(db, "users", userId);
    await updateDoc(userDocRef, { payment: paymentStatus });
    console.log("Payment status updated successfully!");
  } catch (error) {
    console.log("Error updating payment status:", error.message);
  }
};
