const initialState = {
  allCustomers: [],
  loading: false,
  loader: false,
  error: null,
};

const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_USER_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
      };

    case "FETCH_USER_SUCCESS":
      return {
        ...state,
        allCustomers: action.payload,
        loading: false,
      };
    case "FETCH_MORE_USER_SUCCESS":
      console.log('action', action);
      return {
        ...state,
        allCustomers: [...state.allCustomers, ...action.payload],
        loading: false,
      };

    case "FETCH_USER_FAILURE":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case "ADD_USER_LOADER":
      return {
        ...state,
        loader: action.payload,
      };

    default:
      return state;
  }
};

export default customerReducer;
