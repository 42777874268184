import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

const fetchSingleProject = (id, uid) => async (dispatch) => {
  dispatch({
    type: "FETCH_SINGLE_LOADER",
    payload: true,
  });
  try {
    // Dispatch action to clear project state
    dispatch({ type: "CLEAR_SINGLE_PROJECT" });

    const projectsCollection = collection(db, "projects");

    if (id) {
      const projectDoc = doc(projectsCollection, id);
      const projectSnapshot = await getDoc(projectDoc);

      if (projectSnapshot.exists()) {
        const projectData = {
          id: projectSnapshot.id,
          ...projectSnapshot.data(),
        };

        const userDocRef = doc(db, "users", projectData.customerId);
        const userDocSnap = await getDoc(userDocRef);

        const userData = userDocSnap.exists() ? userDocSnap.data() : null;
        projectData.userDetail = userData;

        dispatch({
          type: "FETCH_SINGLE_PROJECT",
          payload: projectData,
        });
      }
    }
    dispatch({
        type: "FETCH_SINGLE_LOADER",
        payload: false,
      });
  } catch (error) {
    console.log("error: ", error);
    dispatch({
        type: "FETCH_SINGLE_ERROR",
        payload: error.message,
      });
    dispatch({
        type: "FETCH_SINGLE_LOADER",
        payload: true,
      });
  }
};
export default fetchSingleProject;
