import { Card, CardBody, Col } from "reactstrap";

const Datenschutz = () => {
  return (
    <Col sm="10">
      <Card
        className="bg-dark shadow border-0 overflow-auto"
        style={{ height: "440px" }}
      >
        <CardBody className="px-lg-5 py-lg-5 text-white">
          <h1 className=" text-white">Datenschutzerklärung</h1>
          <div className="mb-4">
            <h2 className="mb-3 text-white">
              1. Datenschutz auf einen Blick Allgemeine Hinweise
            </h2>
            <h3 className="mb-0 text-white">Allgemeine Hinweise</h3>
            <p className="mb-0">
              Die folgenden Hinweise geben einen einfachen Überblick darüber,
              was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
              Website besuchen. Personenbezogene Daten sind alle Daten, mit
              denen Sie persönlich identifiziert werden können. Ausführliche
              Informationen zum Thema Datenschutz entnehmen Sie unserer unter
              diesem Text aufgeführten Datenschutzerklärung.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">
              Datenerfassung auf dieser Website
              <br />
              <span className="h3 text-white">
                Wer ist verantwortlich für die Datenerfassung auf dieser
                Website?
              </span>
            </h2>
            <p className="mb-0">
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
              „Hinweis zur Verantwortlichen Stelle“ in dieser
              Datenschutzerklärung entnehmen.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">Wie erfassen wir Ihre Daten?</h3>
            <p className="mb-0">
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
              ein Kontaktformular eingeben.
              <br />
              Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
              Besuch der Website durch unsere IT- Systeme erfasst. Das sind vor
              allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
              Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
              automatisch, sobald Sie diese Website betreten.
            </p>
          </div>
          {/* <div className="mb-4">
              <h3 className="mb-0 text-white">EU-Streitschlichtung</h3>
              <p className="mb-0">
                Die Europäische Kommission stellt eine Plattform zur
                Online-Streitbeilegung (OS) bereit:
                <a href="https://ec.europa.eu/consumers/odr/" target="_blank">
                  https://ec.europa.eu/consumers/odr/
                </a>
                .Unsere E-Mail-Adresse finden Sie oben im Impressum.
              </p>
            </div> */}
          <div className="mb-4">
            <h3 className="mb-0 text-white">Wofür nutzen wir Ihre Daten?</h3>
            <p className="mb-0">
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gewährleisten. Andere Daten können
              zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Welche Rechte haben Sie bezüglich Ihrer Daten?
            </h3>
            <p className="mb-0">
              Sie haben jederzeit das Recht, unentgeltlich Auskunft über
              Herkunft, Empfänger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht,
              die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie
              eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie
              diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
              haben Sie das Recht, unter bestimmten Umständen die Einschränkung
              der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
              Aufsichtsbehörde zu.
              <br />
              Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
              sich jederzeit an uns wenden.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Analyse-Tools und Tools von Drittanbietern{" "}
            </h3>
            <p className="mb-0">
              Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
              ausgewertet werden. Das geschieht vor allem mit sogenannten
              Analyseprogrammen.
              <br />
              Detaillierte Informationen zu diesen Analyseprogrammen finden Sie
              in der folgenden Datenschutzerklärung.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">2. Hosting</h2>
            <p className="mb-0">
              Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">Externes Hosting</h3>
            <p className="mb-0">
              Diese Website wird extern gehostet. Die personenbezogenen Daten,
              die auf dieser Website erfasst werden, werden auf den Servern des
              Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um
              IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
              Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
              Daten, die über eine Website generiert werden, handeln.
              <br />
              Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung
              gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs.
              1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
              effizienten Bereitstellung unseres Online-Angebots durch einen
              professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine
              entsprechende Einwilligung abgefragt wurde, erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die
              Speicherung von Cookies oder den Zugriff auf Informationen im
              Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des
              TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.
              <br />
              Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit
              verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten
              erforderlich ist und unsere Weisungen in Bezug auf diese Daten
              befolgen.
              <br />
              Wir setzen folgende(n) Hoster ein:
              <br />
              Google Ireland Limited Gordon House, Barrow Street Dublin 4Irland
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">
              3. Allgemeine Hinweise und Pflichtinformationen{" "}
              <h3 className="mb-0 mt-3 text-white">Datenschutz</h3>
            </h2>
            <p className="mb-0">
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend den gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung.
              <br />
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben. Personenbezogene Daten sind Daten,
              mit denen Sie persönlich identifiziert werden können. Die
              vorliegende Datenschutzerklärung erläutert, welche Daten wir
              erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu
              welchem Zweck das geschieht.
              <br />
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
              B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Hinweis zur verantwortlichen Stelle{" "}
            </h3>
            <p className="mb-0">
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser
              Website ist:
              <br />
              Robin Landwehr Straight Media Mühlwehrstraße 28 97980 Bad
              Mergentheim
              <br />
              Telefon:{" "}
              <a className="text-underline text-white" href="tel:+491604550977">
                +49 160 4550977
              </a>{" "}
              E-Mail:{" "}
              <a
                className="text-underline text-white"
                href="mailto:robin@straight4media.de"
              >
                robin@straight4media.de
              </a>
              <br />
              Verantwortliche Stelle ist die natürliche oder juristische Person,
              die allein oder gemeinsam mit anderen über die Zwecke und Mittel
              der Verarbeitung von personenbezogenen Daten (z. B. Namen,
              E-Mail-Adressen o. Ä.) entscheidet.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">Speicherdauer</h3>
            <p className="mb-0">
              Soweit innerhalb dieser Datenschutzerklärung keine speziellere
              Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen
              Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt.
              Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine
              Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
              gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für
              die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer-
              oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten
              Fall erfolgt die Löschung nach Fortfall dieser Gründe.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
              auf dieser Website
            </h3>
            <p className="mb-0">
              Sofern Sie in die Datenverarbeitung eingewilligt haben,
              verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern
              besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet
              werden. Im Falle einer ausdrücklichen Einwilligung in die
              Übertragung personenbezogener Daten in Drittstaaten erfolgt die
              Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a
              DSGVO. Sofern Sie in die Speicherung von Cookies oder in den
              Zugriff auf Informationen in Ihr Endgerät (z. B. via
              Device-Fingerprinting) eingewilligt haben, erfolgt die
              Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG.
              Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
              Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
              erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6
              Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten,
              sofern diese zur Erfüllung einer rechtlichen Verpflichtung
              erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO.
              Die Datenverarbeitung kann ferner auf Grundlage unseres
              berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen.
              Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird
              in den folgenden Absätzen dieser Datenschutzerklärung informiert.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Empfänger von personenbezogenen Daten{" "}
            </h3>
            <p className="mb-0">
              Im Rahmen unserer Geschäftstätigkeit arbeiten wir mit
              verschiedenen externen Stellen zusammen. Dabei ist teilweise auch
              eine Übermittlung von personenbezogenen Daten an diese externen
              Stellen erforderlich. Wir geben personenbezogene Daten nur dann an
              externe Stellen weiter, wenn dies im Rahmen einer
              Vertragserfüllung erforderlich ist, wenn wir gesetzlich hierzu
              verpflichtet sind (z. B. Weitergabe von Daten an Steuerbehörden),
              wenn wir ein berechtigtes Interesse nach Art. 6 Abs. 1 lit. f
              DSGVO an der Weitergabe haben oder wenn eine sonstige
              Rechtsgrundlage die Datenweitergabe erlaubt. Beim Einsatz von
              Auftragsverarbeitern geben wir personenbezogene Daten unserer
              Kunden nur auf Grundlage eines gültigen Vertrags über
              Auftragsverarbeitung weiter. Im Falle einer gemeinsamen
              Verarbeitung wird ein Vertrag über gemeinsame Verarbeitung
              geschlossen.{" "}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Widerruf Ihrer Einwilligung zur Datenverarbeitung{" "}
            </h3>
            <p className="mb-0">
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
              Einwilligung möglich. Sie können eine bereits erteilte
              Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.{" "}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
              sowie gegen Direktwerbung (Art. 21 DSGVO){" "}
            </h3>
            <p className="mb-0">
              WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
              ODER F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN,
              DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
              VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN;
              DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES
              PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
              VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG.
              WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN
              PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR
              KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG
              NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
              ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
              VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
              DSGVO).
              <br />
              WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG
              ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN
              DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
              ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS
              PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG
              STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN
              ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET
              (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Beschwerderecht bei der zuständigen Aufsichtsbehörde{" "}
            </h3>
            <p className="mb-0">
              Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
              Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
              Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
              oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
              besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.{" "}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">Recht auf Datenübertragbarkeit </h3>
            <p className="mb-0">
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem gängigen,
              maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
              direkte Übertragung der Daten an einen anderen Verantwortlichen
              verlangen, erfolgt dies nur, soweit es technisch machbar ist.{" "}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Auskunft, Berichtigung und Löschung{" "}
            </h3>
            <p className="mb-0">
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
              weiteren Fragen zum Thema personenbezogene Daten können Sie sich
              jederzeit an uns wenden.{" "}
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Recht auf Einschränkung der Verarbeitung{" "}
            </h3>
            <p className="mb-0">
              Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu können Sie sich
              jederzeit an uns wenden. Das Recht auf Einschränkung der
              Verarbeitung besteht in folgenden Fällen:
              <br />
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.Wenn die Verarbeitung Ihrer
              personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie
              statt der Löschung die Einschränkung der Datenverarbeitung
              verlangen.
              <br />
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.Wenn Sie einen Widerspruch
              nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung
              zwischen Ihren und unseren Interessen vorgenommen werden. Solange
              noch nicht feststeht, wessen Interessen überwiegen, haben Sie das
              Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen
              Daten zu verlangen.
              <br />
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
              abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
              der Rechte einer anderen natürlichen oder juristischen Person oder
              aus Gründen eines wichtigen öffentlichen Interesses der
              Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-3 text-white">
              4. Datenerfassung auf dieser Website Cookies{" "}
            </h2>
            <h3 className="mb-0 text-white">Cookies </h3>
            <p className="mb-0">
              Unsere Internetseiten verwenden so genannte „Cookies“. Cookies
              sind kleine Datenpakete und richten auf Ihrem Endgerät keinen
              Schaden an. Sie werden entweder vorübergehend für die Dauer einer
              Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf
              Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres
              Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
              Endgerät gespeichert, bis Sie diese selbst löschen oder eine
              automatische Löschung durch Ihren Webbrowser erfolgt.
              <br />
              Cookies können von uns (First-Party-Cookies) oder von
              Drittunternehmen stammen (sog. Third-Party- Cookies).
              Third-Party-Cookies ermöglichen die Einbindung bestimmter
              Dienstleistungen von Drittunternehmen innerhalb von Webseiten (z.
              B. Cookies zur Abwicklung von Zahlungsdienstleistungen).
              <br />
              Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
              technisch notwendig, da bestimmte Webseitenfunktionen ohne diese
              nicht funktionieren würden (z. B. die Warenkorbfunktion oder die
              Anzeige von Videos). Andere Cookies können zur Auswertung des
              Nutzerverhaltens oder zu Werbezwecken verwendet werden.
              <br />
              Cookies, die zur Durchführung des elektronischen
              Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen
              erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur
              Optimierung der Website (z. B. Cookies zur Messung des
              Webpublikums) erforderlich sind (notwendige Cookies), werden auf
              Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine
              andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat
              ein berechtigtes Interesse an der Speicherung von notwendigen
              Cookies zur technisch fehlerfreien und optimierten Bereitstellung
              seiner Dienste. Sofern eine Einwilligung zur Speicherung von
              Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt
              wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage
              dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1
              TTDSG); die Einwilligung ist jederzeit widerrufbar.
              <br />
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen
              von Cookies informiert werden und Cookies nur im Einzelfall
              erlauben, die Annahme von Cookies für bestimmte Fälle oder
              generell ausschließen sowie das automatische Löschen der Cookies
              beim Schließen des Browsers aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
              <br />
              Welche Cookies und Dienste auf dieser Website eingesetzt werden,
              können Sie dieser Datenschutzerklärung entnehmen.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">Server-Log-Dateien</h3>
            <p className="mb-0">
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-Log- Dateien, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
              <br />
              Browsertyp und Browserversion verwendetes Betriebssystem Referrer
              URL Hostname des zugreifenden Rechners Uhrzeit der Serveranfrage
              IP-Adresse
              <br />
              Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
              nicht vorgenommen.
              <br />
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website – hierzu müssen die Server-Log-Files erfasst
              werden.
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Anfrage per E-Mail, Telefon oder Telefax
            </h3>
            <p className="mb-0">
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
              <br />
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde; die Einwilligung ist jederzeit
              widerrufbar.
              <br />
              Die von Ihnen an uns per Kontaktanfragen übersandten Daten
              verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck für die
              Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
              Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
              insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">
              5. Newsletter{" "}
              <span className="h3 text-white">Newsletterdaten</span>
            </h2>
            <p className="mb-0">
              Wenn Sie den auf der Website angebotenen Newsletter beziehen
              möchten, benötigen wir von Ihnen eine E- Mail-Adresse sowie
              Informationen, welche uns die Überprüfung gestatten, dass Sie der
              Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang
              des Newsletters einverstanden sind. Weitere Daten werden nicht
              bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
              ausschließlich für den Versand der angeforderten Informationen und
              geben diese nicht an Dritte weiter.
              <br />
              Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
              Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung
              (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
              Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
              Versand des Newsletters können Sie jederzeit widerrufen, etwa über
              den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits
              erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
              unberührt.
              <br />
              Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
              hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem
              Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert
              und nach der Abbestellung des Newsletters oder nach Zweckfortfall
              aus der Newsletterverteilerliste gelöscht. Wir behalten uns vor,
              E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem
              Ermessen im Rahmen unseres berechtigten Interesses nach Art. 6
              Abs. 1 lit. f DSGVO zu löschen oder zu sperren.
              <br />
              Daten, die zu anderen Zwecken bei uns gespeichert wurden, bleiben
              hiervon unberührt.
              <br />
              Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
              E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
              einer Blacklist gespeichert, sofern dies zur Verhinderung
              künftiger Mailings erforderlich ist. Die Daten aus der Blacklist
              werden nur für diesen Zweck verwendet und nicht mit anderen Daten
              zusammengeführt. Dies dient sowohl Ihrem Interesse als auch
              unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim
              Versand von Newslettern (berechtigtes Interesse im Sinne des Art.
              6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist
              zeitlich nicht befristet.{" "}
              <strong>
                Sie können der Speicherung widersprechen, sofern Ihre Interessen
                unser berechtigtes Interesse überwiegen.
              </strong>
            </p>
          </div>
          <div className="mb-4">
            <h3 className="mb-0 text-white">
              Newsletterversand an Bestandskunden
            </h3>
            <p className="mb-0">
              Wenn Sie Waren oder Dienstleistungen bei uns bestellen und hierbei
              Ihre E-Mail-Adresse hinterlegen, kann diese E-Mail-Adresse in der
              Folge durch uns für den Versand von Newslettern verwendet werden,
              sofern wir Sie vorab hierüber informieren. In einem solchen Fall
              wird über den Newsletter nur Direktwerbung für eigene ähnliche
              Waren oder Dienstleistungen versendet. Die Zusendung dieses
              Newsletters kann von Ihnen jederzeit gekündigt werden. Zu diesem
              Zweck findet sich in jedem Newsletter ein entsprechender Link.
              Rechtsgrundlage für den Versand des Newsletters ist in diesem Fall
              Art. 6 Abs. 1 lit. f DSGVO in Verbindung mit § 7 Abs. 3 UWG.
              <br />
              Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
              E-Mail-Adresse bei uns ggf. in einer Blacklist gespeichert, um
              künftige Mailings an Sie zu verhindern. Die Daten aus der
              Blacklist werden nur für diesen Zweck verwendet und nicht mit
              anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse
              als auch unserem Interesse an der Einhaltung der gesetzlichen
              Vorgaben beim Versand von Newslettern (berechtigtes Interesse im
              Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der
              Blacklist ist zeitlich nicht befristet. Sie können der Speicherung
              widersprechen, sofern Ihre Interessen unser berechtigtes Interesse
              überwiegen.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">
              6. Plugins und Tools{" "}
              <span className="mt-3 h3 text-white">
                Google Fonts (lokales Hosting)
              </span>
            </h2>
            <p className="mb-0">
              Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten
              so genannte Google Fonts, die von Google bereitgestellt werden.
              Die Google Fonts sind lokal installiert. Eine Verbindung zu
              Servern von Google findet dabei nicht statt.
              <br />
              Weitere Informationen zu Google Fonts finden Sie unter{" "}
              <a
                className="text-underline text-white"
                href="https://developers.google.com/fonts/faq"
                target="_blank"
              >
                https://developers.google.com/fonts/faq
              </a>{" "}
              und in der Datenschutzerklärung von Google:{" "}
              <a
                className="text-underline text-white"
                href="https://policies.google.com/privacy?hl=de"
                target="_blank"
              >
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
          </div>
          <div className="pb-5">
            <h2 className="mb-0 text-white">
              7. Eigene Dienste{" "}
              <span className="mt-3 h3 text-white">Google Drive</span>
            </h2>
            <p className="mb-0">
              Wir haben Google Drive auf dieser Website eingebunden. Anbieter
              ist die Google Ireland Limited („Google“), Gordon House, Barrow
              Street, Dublin 4, Irland.
              <br />
              Google Drive ermöglicht es uns, einen Uploadbereich auf unserer
              Website einzubinden, in dem Sie Inhalte hochladen können. Wenn Sie
              Inhalte hochladen, werden diese auf den Servern von Google Drive
              gespeichert. Wenn Sie unsere Website betreten, wird außerdem eine
              Verbindung zu Google Drive aufgebaut, sodass Google Drive
              feststellen kann, dass Sie unsere Website besucht haben.
              <br />
              Die Verwendung von Google Drive erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einem zuverlässigen Uploadbereich auf seiner Website.
              Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt
              die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1
              lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
              <br />
              Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US
              Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen
              zwischen der Europäischen Union und den USA, der die Einhaltung
              europäischer Datenschutzstandards bei Datenverarbeitungen in den
              USA gewährleisten soll. Jedes nach dem DPF zertifizierte
              Unternehmen verpflichtet sich, diese Datenschutzstandards
              einzuhalten. Weitere Informationen hierzu erhalten Sie vom
              Anbieter unter folgendem Link:{" "}
              <a
                className="text-underline text-white"
                href="https://www.dataprivacyframework.gov/s/participant-search/participant- detail?contact=true&id=a2zt000000001L5AAI&status=Active"
                target="_blank"
              >
                https://www.dataprivacyframework.gov/s/participant-search/participant-
                detail?contact=true&id=a2zt000000001L5AAI&status=Active
              </a>
              <br />
              Quelle:
              <br />
              <a
                className="text-underline text-white"
                href="https://www.e-recht24.de"
                target="_blank"
              >
                https://www.e-recht24.de
              </a>
            </p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Datenschutz;
