import { Button, Table } from "reactstrap";
import defaultImg from "../../assets/img/profileIcon_Black.png";

const CustomerTable = ({
  handleViewDetails,
  allCustomers,
  handleRejected,
  handleApproved,
  hideRows,
}) => {

  return (
    <>
      {allCustomers.length !== 0 ? (
        <Table className=" mt-5" responsive>
          <thead className="bg-dark text-center">
            <tr>
              <th scope="col" className="font-weight-bolder text-white">
                #
              </th>
              <th scope="col" className="font-weight-bolder text-white">
                Kundin
              </th>
              <th scope="col" className="font-weight-bolder text-white">
                Email
              </th>
              <th scope="col" className="font-weight-bolder text-white">
                Telefonnummer
              </th>
              <th scope="col" className="font-weight-bolder text-white">
                Adresse
              </th>
              {hideRows === true ? (
                <>
                  <th scope="col" className="font-weight-bolder text-white">
                    Name der Firma
                  </th>
                  <th scope="col" className="font-weight-bolder text-white">
                    videoCompleted
                  </th>
                  <th scope="col" className="font-weight-bolder text-white">
                    Videos In Bearbeitung
                  </th>
                </>
              ) : null}
              <th scope="col" className="font-weight-bolder text-white">
                Kundennummer
              </th>
              <th scope="col" className="font-weight-bolder text-white">
                AKTION
              </th>
            </tr>
          </thead>
          {allCustomers?.map((data, index) => {
            return (
              <tbody key={data.id} className=" text-center">
                <tr>
                  <th scope="row">{index + 1}</th>
                  <td className="d-flex justify-content-start align-items-center">
                    <img
                      src={
                        data?.profileImage !== undefined &&
                        data?.profileImage !== null
                          ? data?.profileImage
                          : defaultImg
                      }
                      onError={(e) => {
                        e.target.src = defaultImg;
                      }}
                      className="rounded-circle mr-3 object_cover bg-light"
                      alt="clock"
                      width={30}
                      height={30}
                    />
                    {data?.fullname}
                  </td>
                  <td>{data?.email}</td>
                  <td>{data?.phoneNumber}</td>
                  <td>{data?.address}</td>
                  <td>{data?.companyName}</td>
                  {hideRows === true ? (
                    <>
                      <td>{data?.videosCompleted}</td>
                      <td>{data?.videosInprogress}</td>
                      <td>{data?.customerNumber}</td>
                    </>
                  ) : null}
                  {hideRows === true ? (
                    <td className="text-center">
                      <Button
                        color="success"
                        size="sm"
                        className=""
                        onClick={() => handleViewDetails(data)}
                      >
                        View Details
                      </Button>
                    </td>
                  ) : (
                    <td className="text-center">
                      <Button
                        color="success"
                        size="sm"
                        className=""
                        onClick={() => handleApproved(data)}
                      >
                        Approved
                      </Button>
                      <Button
                        color="danger"
                        size="sm"
                        className=""
                        onClick={() => handleRejected(data)}
                      >
                        Rejected
                      </Button>
                    </td>
                  )}
                </tr>
              </tbody>
            );
          })}
        </Table>
      ) : (
        <p className="mb-0 text-sm text-center mt-5 mb-4">
          <b>Keine Daten gefunden</b>
        </p>
      )}
    </>
  );
};

export default CustomerTable;
