import { Card, CardBody, Col } from "reactstrap";

const Agbs = () => {
  return (
    <Col sm="10">
      <Card
        className="bg-dark shadow border-0 overflow-auto"
        style={{ height: "440px" }}
      >
        <CardBody className="px-lg-5 py-lg-5 text-white">
          <h1 className="mb-4 text-white">
            Allgemeine Geschäftsbedingungen (AGBs) für die Nutzung der Straight
            Media Social Webplattform zur externen Bearbeitung von
            Social-Media-Videos:
          </h1>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Vertragsgegenstand</h2>
            <p className="mb-0">
              Die vorliegenden Allgemeinen Geschäftsbedingungen regeln die
              Nutzung der Webplattform zur externen Bearbeitung von
              Social-Media-Videos (im Folgenden „Straight Media Socials“
              genannt) durch die Kunden.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Leistungen der Plattform</h2>
            <p className="mb-0">
              Die Plattform ermöglicht es den Kunden, ihre eigenen
              selbstgedrehten und unbearbeiteten Videos hochzuladen. <br />
              Die Plattform übernimmt die Bearbeitung dieser Videos gemäß den
              Anweisungen des Kunden. Die bearbeiteten Videos können von den
              Kunden eingesehen, überprüft und genehmigt oder zweimal kostenlos
              überarbeitet werden.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Urheberrecht und Verantwortung</h2>
            <p className="mb-0">
              Die Plattform übernimmt keine Verantwortung für die im
              bearbeiteten Video verwendete Musik und Videos. <br />
              Es liegt in der Verantwortung des Kunden, sicherzustellen, dass
              alle Inhalte den geltenden Urheberrechtsbestimmungen entsprechen.
              Der Kunde ist vor dem Upload selbst für die Überprüfung der
              Urheberrechte verantwortlich.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Genehmigung und Revision</h2>
            <p className="mb-0">
              Ein Video gilt als genehmigt, wenn es innerhalb von 7 Tagen nach
              Fertigstellung nicht überprüft oder eine Überarbeitung angefordert
              wurde. Jede Überarbeitung des Videos ist zweimal kostenlos. Nach
              der Genehmigung eines Videos durch den Kunden können keine
              weiteren Überarbeitungen angefordert werden.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Fertigstellungszeit</h2>
            <p className="mb-0">
              Die auf der Plattform angegebene Fertigstellungszeit von 48
              Stunden dient lediglich als Richtwert. Die Plattform garantiert
              keine Fertigstellung innerhalb dieses Zeitrahmens, da die
              Bearbeitungsdauer aufgrund der Auslastung der Plattform variieren
              kann. <br />
              Die Auswahl der 24-Stunden-Priorisierung garantiert ebenfalls
              keine Fertigstellung innerhalb von 24 Stunden. Falls das
              Videoprojekt nicht innerhalb von 24 Stunden fertiggestellt werden
              kann, entfallen die zusätzlichen Kosten für die
              24-Stunden-Priorisierung.
              <br />
              Sollte die Plattform die 24-Stunden-Frist während einer Revision
              nicht einhalten können, werden die Kosten für die
              24-Stunden-Priorisierung dennoch vollständig berechnet.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Ablehnung von Videoprojekten</h2>
            <p className="mb-0">
              Die Plattform behält sich das Recht vor, Videoprojekte abzulehnen
              oder nicht zu bearbeiten, wenn der Kunde die Anweisungen zur
              Videoproduktion nicht befolgt. <br />
              Dazu gehören die vorgegebenen Kameraeinstellungen und
              Filmanweisungen.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Schlussbestimmungen</h2>
            <p className="mb-0">
              Diese Allgemeinen Geschäftsbedingungen stellen die gesamte
              Vereinbarung zwischen der Plattform und dem Kunden dar und
              ersetzen alle vorherigen Vereinbarungen oder Absprachen.
              <br />
              Änderungen oder Ergänzungen dieser Bedingungen bedürfen der
              Schriftform.
              <br />
              Diese Allgemeinen Geschäftsbedingungen treten mit der Nutzung der
              Plattform durch den Kunden in Kraft.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Bestätigung der AGBs und Kosten</h2>
            <p className="mb-0">
              Vor Aufgabe jedes Videoprojekts bestätigt der Kunde ausdrücklich,
              dass er die Allgemeinen Geschäftsbedingungen gelesen hat, diesen
              zustimmt und sich bewusst ist, dass ihm das bearbeitete Video in
              Rechnung gestellt wird.
            </p>
          </div>
          <div className="pb-5">
            <h2 className="mb-0 text-white">Rechnungsstellung</h2>
            <p className="mb-0">
              Die Rechnungsstellung erfolgt am letzten Tag eines jeden Monats
              für alle im aktuellen Monat abgeschlossenen Videoprojekte. <br />
              Die Rechnung wird am ersten Tag des folgenden Monats versendet. In
              der Rechnung werden alle bearbeiteten Videos einzeln aufgeführt
              und mit einer eindeutigen Auftragsnummer versehen.
            </p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Agbs;
