export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const AUTH_SET_LOADING = "AUTH_SET_LOADING";

export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS'
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAIURE'
export const FETCH_MORE_NOTIFICATIONS_SUCCESS = 'FETCH_MORE_NOTIFICATIONS_SUCCESS'
export const NOTIFICATIONS_SET_LOADING = 'NOTIFICATIONS_LOADER'
export const NOTIFICATIONS_FETCH_REQUEST = 'NOTIFICATIONS_FETCH_REQUEST'

export const FETCH_SINGLE_PROJECT = 'FETCH_SINGLE_PROJECT'
export const FETCH_SINGLE_SUBMISSION = 'FETCH_SINGLE_SUBMISSION'

export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const ADD_PROJECT_LOADER = "ADD_PROJECT_LOADER";
export const ADD_SUBMISSION_LOADER = "ADD_SUBMISSION_LOADER";
export const VIDEO_UPLOAD_PROGRESS = "VIDEO_UPLOAD_PROGRESS";
export const UPLOAD_FILES_START = "UPLOAD_FILES_START";
export const AUTH_NOTIFICATION = "AUTH_NOTIFICATION";
export const FETCH_PROJECTS_REQUEST = "FETCH_PROJECTS_REQUEST";
export const FETCH_PROJECTS_SUCCESS = "FETCH_PROJECTS_SUCCESS";
export const FETCH_PROJECTS_FAILURE = "FETCH_PROJECTS_FAILURE";
export const FETCH_SUBMISSION_REQUEST = "FETCH_SUBMISSION_REQUEST";
export const FETCH_SUBMISSION_SUCCESS = "FETCH_SUBMISSION_SUCCESS";
export const FETCH_SUBMISSION_FAILURE = "FETCH_SUBMISSION_FAILURE";
export const SET_USER_DATA = "SET_USER_DATA";
export const UPDATE_USER = 'UPDATE_USER';