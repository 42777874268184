import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import {
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
  VIDEO_UPLOAD_PROGRESS
} from "store/types";
import generateFileName from "utils/generateFileName";
import { db } from "../../config/firebase";

export const addProject =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(loader(true));

      let videoFileUrls = [];

      if (payload.videoFiles && payload.videoFiles.length > 0) {
        videoFileUrls = await Promise.all(
          payload.videoFiles.map(async (videoFile) => {
            return await uploadFile(videoFile, dispatch);
          })
        );
        delete payload.videoFiles;
      }

      const projectData = {
        ...payload,
        videoFileUrls,
        createdAt: Timestamp.now(),
      };

      await addDoc(collection(db, "projects"), projectData);

      dispatch(loader(false));
      onSuccess();
    } catch (error) {
      dispatch(loader(false));
    }
  };

export const loader = (value) => async (dispatch) => {
  dispatch({
    type: "ADD_PROJECT_LOADER",
    payload: value,
  });
};

export const videoUploadingProgress = (value) => async (dispatch) => {
  dispatch({
    type: VIDEO_UPLOAD_PROGRESS,
    payload: value,
  });
};

export const uploadFile = async (file, dispatch) => {
  try {
    let fileName = generateFileName(file.name);
    const storage = getStorage();
    const storageReference = ref(storage, "files/" + fileName);
    const uploadTask = uploadBytesResumable(storageReference, file);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log(`Upload is ${progress}% done`);
          dispatch(videoUploadingProgress(progress));
        },
        (error) => {
          console.error("Fehler beim Hochladen der Datei", error);
          reject({ stage: "Hochladen", error });
        },
        async () => {
          // File uploaded successfully
          // console.log("File uploaded successfully");
          try {
            // Get the download URL
            const downloadURL = await getDownloadURL(storageReference);
            // console.log("Download URL:", downloadURL);
            resolve(downloadURL);
          } catch (error) {
            console.error("Fehler beim Abrufen der Download-URL", error);
            reject({ stage: "Download-URL abrufen", error });
          }
        }
      );
    });
  } catch (error) {
    console.error("Fehler in der Funktion „uploadFile“.", error);
    throw error;
    uid;
  }
};

export const fetchProjectsById = (user) => async (dispatch) => {
  dispatch({
    type: FETCH_PROJECTS_REQUEST,
    payload: null,
  });
  try {
    dispatch(loader(true));
    let fetchLimit = 10;
    const projectsCollection = query(collection(db, "projects"));
    let projectData = [];
    let idType = user?.userType == "customer" ? "customerId" : "editorId";

    projectData = query(
      projectsCollection,
      where(idType, "==", user?.id),
      orderBy("createdAt", "desc"),
      limit(fetchLimit)
    );

    const querySnapshot = await getDocs(projectData);
    let projects = [];
    for (let document of querySnapshot.docs) {
      let docData = document.data();
      const docRef = doc(db, "users", docData?.customerId);
      const docSnap = await getDoc(docRef);
      projects.push({
        id: document.id,
        ...document.data(),
        customer: docSnap.data(),
      });
    }
    dispatch({
      type: FETCH_PROJECTS_SUCCESS,
      payload: projects,
    });
    dispatch(loader(false));
  } catch (error) {
    dispatch({
      type: FETCH_PROJECTS_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchMoreProjects =
  (user, lastItem, onError = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_PROJECTS_REQUEST,
    });
    try {
      let fetchLimit = 10;
      const projectsCollection = query(collection(db, "projects"));
      let projectData = [];
      if (user?.userType == "customer") {
        projectData = query(
          projectsCollection,
          where("customerId", "==", user?.id),
          orderBy("createdAt", "desc"),
          startAfter(lastItem.createdAt),
          limit(fetchLimit)
        );
      } else {
        projectData = query(
          projectsCollection,
          where("editorId", "==", user?.id),
          orderBy("createdAt", "desc"),
          startAfter(lastItem.createdAt),
          limit(fetchLimit)
        );
      }

      const querySnapshot = await getDocs(projectData);
      let projects = [];
      for (let document of querySnapshot.docs) {
        let docData = document.data();
        const docRef = doc(db, "users", docData?.customerId);
        const docSnap = await getDoc(docRef);
        projects.push({
          id: document.id,
          ...document.data(),
          customer: docSnap.data(),
        });
      }

      if (projects?.length < fetchLimit) {
        onError();
      }

      dispatch({
        type: "FETCH_MORE_PROJECTS_SUCCESS",
        payload: projects,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PROJECTS_FAILURE,
        payload: error.message,
      });
    }
  };

export const fetchAllProjects = () => async (dispatch) => {
  dispatch({
    type: FETCH_PROJECTS_REQUEST,
    payload: null,
  });

  try {
    dispatch(loader(true));
    const fetchLimit = 10;
    const projectsCollection = collection(db, "projects");

    const projectData = await getDocs(
      query(projectsCollection, orderBy("createdAt", "desc"), limit(fetchLimit))
    );

    const projects = await Promise.all(
      projectData.docs.map(async (document) => {
        const docData = document.data();
        const userId = docData.customerId;

        const userDocRef = doc(db, "users", userId);
        const userDocSnap = await getDoc(userDocRef);
        const userData = userDocSnap.exists() ? userDocSnap.data() : null;

        return {
          id: document.id,
          ...docData,
          customer: userData,
        };
      })
    );

    dispatch({
      type: FETCH_PROJECTS_SUCCESS,
      payload: projects,
    });

    dispatch(loader(false));
  } catch (error) {
    console.error("Error fetching projects:", error);
    dispatch({
      type: FETCH_PROJECTS_FAILURE,
      payload: error.message,
    });
  }
};

export const fetchAllMoreProjects =
  (lastItem, onError = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_PROJECTS_REQUEST,
    });

    try {
      const fetchLimit = 10;
      const projectsCollection = collection(db, "projects");

      const projectData = query(
        projectsCollection,
        orderBy("createdAt", "desc"),
        startAfter(lastItem?.createdAt),
        limit(fetchLimit)
      );

      const querySnapshot = await getDocs(projectData);
      let projects = [];

      for (let document of querySnapshot.docs) {
        let docData = document.data();
        const docRef = doc(db, "users", docData?.customerId);
        const docSnap = await getDoc(docRef);

        projects.push({
          id: document.id,
          ...document.data(),
          customer: docSnap.data(),
        });
      }

      if (projects?.length < fetchLimit) {
        onError();
      }

      dispatch({
        type: "FETCH_MORE_PROJECTS_SUCCESS",
        payload: projects,
      });
    } catch (error) {
      dispatch({
        type: FETCH_PROJECTS_FAILURE,
        payload: error.message,
      });
    }
  };
