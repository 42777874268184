/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { logout } from "store/actions/authAction";
import socialsImg from "../../assets/img/logo_socials.png";
import avatar from "../../assets/img/profileIcon.png";
import logout_b from "../../assets/img/logout_b.png";
import whatsapp_b from "../../assets/img/whatsapp_b.png";
import phone_b from "../../assets/img/phone_b.png";
import questionMark_b from "../../assets/img/questionMark_b.png";
import hoursIcon from "../../assets/img/hoursIcon.png";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { refreshAuth } from "store/actions/authAction";

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const [collapseOpen, setCollapseOpen] = useState();
  
  // const [notifications, setNotifications] = useState();
  // verifies if routeName is the one active (in browser input)
  // const activeRoute = routeName => {
  // 	return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  // };
  // toggles collapse between opened and closed (true/false)

  const {uid, user } = useSelector((state) => state.authUser);
  // const { notifications } = useSelector((state) => state.notifications);

  useEffect(() => {
    if(uid){
      dispatch(refreshAuth(user?.id));
    }
  }, [uid]);
  
  // useEffect(() => {
  //   setTimeout(() => {
  //     dispatch(refreshAuth(user?.id));
  //   }, 3000);
  //   const notify = dispatch(refreshAuth(user?.id));
  //   //setNotifications(user?.notifications);
  //   // console.log("notify", notify);
  // }, [notifications]);

  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes
      .filter((prop) => {
        if (prop.isMenu === false) return false;
        if (prop.roles && !prop.roles.includes(user?.userType)) return false;
        return true;
      })
      .map((prop, key) => (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <span className="position-relative">
              <img className="mr-2" src={prop?.icon} alt="icons" width={30} />
              <Badge
                className={`position-absolute right-2 bg-danger notify top-0 p-0 align-items-center justify-content-center ${
                  prop.name == "Benachrichtigungen" ? "d-flex" : "d-none"
                }`}
                pill
              >
                {user?.notifications}
              </Badge>
            </span>{" "}
            {prop.name}
          </NavLink>
        </NavItem>
      ));
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-dark pt-4"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon " />
        </button>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="p-0" {...navbarBrandProps}>
            <img className="navbar-brand-img" src={logo.imgSrc} alt={"logo"} />
            <div className="d-flex justify-content-center">
              <img
                className="d-md-none"
                src={socialsImg}
                alt="logo"
                width={40}
              />
            </div>
          </NavbarBrand>
        ) : null}
        {/* User */}

        <Nav className="align-items-center d-md-none">
          {/* <UncontrolledDropdown nav>
						<DropdownToggle nav className='nav-link-icon'>
							<i className='ni ni-bell-55 text-white' />
						</DropdownToggle>
						<DropdownMenu
							aria-labelledby='navbar-default_dropdown_1'
							className='dropdown-menu-arrow'
							right
						>
							<DropdownItem>Action</DropdownItem>
							<DropdownItem>Another action</DropdownItem>
							<DropdownItem divider />
							<DropdownItem>Something else here</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown> */}
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="avatar"
                    src={user?.profileImage ? user?.profileImage : avatar}
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">{user?.fullname}</h6>
              </DropdownItem>
              <DropdownItem className="d-md-flex d-lg-none" divider />
              <DropdownItem className="d-flex" to="" tag={Link}>
                <img
                  className="mr-2"
                  src={questionMark_b}
                  alt="?mark"
                  width={20}
                />
                <span>ANLEITUNG</span>
              </DropdownItem>
              <DropdownItem className="d-flex">
                <a
                  href="https://wa.me/+491604550977?text=Ich%20habe%20eine%20Frage%20zu%20der%20Socials%20Plattform."
                  target="_blank"
                  className="text-dark"
                >
                  <img
                    className="mr-2"
                    src={whatsapp_b}
                    alt="whatsapp"
                    width={20}
                  />
                  <span>WHATS APP</span>
                </a>
              </DropdownItem>
              <DropdownItem className="d-flex">
                <a
                  href="tel:+491604550977"
                  className="text-dark"
                  target="_blank"
                >
                  <img className="mr-2" src={phone_b} alt="phone" width={20} />
                  <span>CALL US</span>
                </a>
              </DropdownItem>
              <DropdownItem
                className="d-flex justify-content-center"
                to=""
                tag={Link}
              >
                <img
                  style={{ cursor: "pointer" }}
                  src={hoursIcon}
                  alt="48h"
                  width={100}
                />
              </DropdownItem>
              <DropdownItem className="d-flex" divider />
              <DropdownItem
                onClick={() => {
                  dispatch(logout());
                  localStorage.removeItem("auth");
                }}
              >
                <img className="mr-2" src={logout_b} alt="logout" width={20} />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  color="success"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          {/* <Form className='mt-4 mb-3 d-md-none'>
						<InputGroup className='input-group-rounded input-group-merge'>
							<Input
								aria-label='Search'
								className='form-control-rounded form-control-prepended'
								placeholder='Search'
								type='search'
							/>
							<InputGroupAddon addonType='prepend'>
								<InputGroupText>
									<span className='fa fa-search' />
								</InputGroupText>
							</InputGroupAddon>
						</InputGroup>
					</Form> */}
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
