/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { signUp } from "store/actions/authAction";
import { useHistory } from "react-router-dom";
import HeadingCard from "components/HeadingCard";
import { ToastContainer } from "react-toastify";

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading } = useSelector((state) => state.authUser);

  const initialState = {
    fullname: "",
    email: "",
    companyName: '',
    customerNumber: '',
    phoneNumber: '',
    address: '',
    password: "",
    notifications: 0,
    videosInprogress: 0,
    videosCompleted: 0,
    userType: "customer",
    request: "pending",
    payment: true,
  };
  
  const [newUser, setNewUser] = useState(initialState);
  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewUser((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(
      signUp(newUser, () => {
        setTimeout(() => {
          history.push("/auth/login");
        }, 2000);
      })
    );
    setNewUser(initialState);
  };

  return (
    <>
      <Col lg="6" md="8">
        <div className="mb--4">
          <HeadingCard title="SIGN UP" />
        </div>
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={handleSubmit}>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-hat-3" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Full Name"
                        name="fullname"
                        type="text"
                        value={newUser.fullname}
                        onChange={handleChange}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        name="email"
                        type="email"
                        autoComplete="new-email"
                        value={newUser.email}
                        onChange={handleChange}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        name="password"
                        type="password"
                        autoComplete="new-password"
                        value={newUser.password}
                        onChange={handleChange}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="ni ni-hat-3" /> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Company Name"
                        name="companyName"
                        type="text"
                        value={newUser.companyName}
                        onChange={handleChange}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                {/* <Col xs={6}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Customer Number"
                        name="customerNumber"
                        type="number"
                        value={newUser.customerNumber}
                        onChange={handleChange}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col> */}
                <Col xs={6}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="ni ni-hat-3" /> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Phone Number"
                        name="phoneNumber"
                        type="number"
                        value={newUser.phoneNumber}
                        onChange={handleChange}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative mb-3">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          {/* <i className="ni ni-hat-3" /> */}
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Address"
                        name="address"
                        type="text"
                        value={newUser.address}
                        onChange={handleChange}
                        required
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              {/* <Row className="my-4">
                <Col xs="12">
                  <div className="custom-control custom-control-alternative custom-checkbox">
                    <input
                      className="custom-control-input"
                      id="customCheckRegister"
                      type="checkbox"
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheckRegister"
                    >
                      <span className="text-muted">
                        I agree with the{" "}
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Privacy Policy
                        </a>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row> */}
              <div className="text-center">
                <Button
                  className="mt-4"
                  color="success"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? <Spinner /> : "Create account"}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col>
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Already have an account? login</small>
            </a>
          </Col>
        </Row> */}
        <ToastContainer autoClose={500} />
      </Col>
    </>
  );
};

export default Register;
