import HeadingCard from "components/HeadingCard";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Row, Spinner, Table } from "reactstrap";
import { fetchMoreNotifications } from "store/actions/addNotifications";
import {
  fetchNotifications,
  updateNotificationAction,
} from "store/actions/addNotifications";

const NotifyTable = () => {
  const dispatch = useDispatch();
  const { uid } = useSelector((state) => state.authUser);
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const { notifications, loading, loader } = useSelector(
    (state) => state.notifications
    );
    console.log('notifications: ', notifications);

  const handleLoadMore = () => {
    const lastItem = notifications[notifications?.length - 1];
    dispatch(
      fetchMoreNotifications(uid, lastItem, () => {
        setDisableLoadMore(true);
      })
    );
  };

  useEffect(() => {
    if (uid !== null) {
      dispatch(fetchNotifications(uid, ));
    }
  }, [uid]);

  return (
    <Row>
      <div className="col mb-4">
        <div className="mb--4">
          <HeadingCard title="Benachrichtigungen" />
        </div>
        <Card className="shadow">
          {loader ? (
            <div className="mx-auto my-5">
              <Spinner />
            </div>
          ) : (
            <>
              {notifications.length !== 0 ? (
                <Table className="mt-5" responsive>
                  <thead>
                    <tr>
                      <th scope="col" className="font-weight-bolder">
                      Projektnummer
                      </th>
                      <th scope="col" className="font-weight-bolder">
                        TAG UND ZEIT
                      </th>
                      <th scope="col" className="font-weight-bolder">
                        DETAILS
                      </th>
                      {/* <th scope="col" className="font-weight-bolder">NAME</th> */}
                      <th scope="col" className="font-weight-bolder">
                        Aktion
                      </th>
                    </tr>
                  </thead>
                  {notifications?.map((data, index) => {
                    return (
                      <tbody key={index}>
                        <tr>
                          <th scope="row">{data?.projectNo}</th>
                          <td>
                            {data.createdAt && (
                              <span>
                                {moment(data?.createdAt.toDate()).format(
                                  "DD.MM.YYYY HH:mm:ss"
                                )}
                              </span>
                            )}
                          </td>
                          <td>{data.details}</td>

                          <td>
                            <Button
                              color="primary"
                              size="sm"
                              disabled={data?.isRead}
                              onClick={() =>
                                dispatch(
                                  updateNotificationAction(
                                    data?.id,
                                    !data?.isRead
                                  )
                                )
                              }
                            >
                              als gelesen markieren
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
              ) : (
                <p className="mb-0 text-sm text-center mt-5 mb-4">
                  <b>Keine Daten gefunden</b>
                </p>
              )}

              {notifications?.length !== 0 && !disableLoadMore && (
                <Row className="justify-content-start align-items-center m-3">
                  <Button color="success" size="sm" onClick={handleLoadMore}>
                    {loading ? <Spinner size="sm" /> : "Mehr laden"}
                  </Button>
                </Row>
              )}
            </>
          )}
        </Card>
      </div>
    </Row>
  );
};

export default NotifyTable;
