import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HeadingCard from "../components/HeadingCard";
import { addProject } from "../store/actions/addProject";
// Reactstrap imports
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Progress,
  Row,
  Spinner,
} from "reactstrap";
// Component Imports
import OnlyHeader from "components/Headers/OnlyHeader";
import RadioOptions from "components/NewProject/RadioOptions";
// Images Imports
import CreditCard from "components/payments/CreditCard";
import { useHistory } from "react-router-dom";
import hourIcon from "../assets/img/brand/24h 1.svg";
import videolangeimage1 from "../assets/img/brand/30s.svg";
import videolangeimage2 from "../assets/img/brand/45s.svg";
import videolangeimage3 from "../assets/img/brand/60s.svg";
import instagramIcon from "../assets/img/brand/Instagram 1.svg";
import tiktokIcon from "../assets/img/brand/TikTok 1.svg";
import still1 from "../assets/img/brand/UNTERTITEL_STIL_1.png";
import still2 from "../assets/img/brand/UNTERTITEL_STIL_2.png";
import andereIcon from "../assets/img/brand/andere.svg";
import uploadIcon from "../assets/img/brand/upload.svg";
import wrongIcon from "../assets/img/brand/wrong 1.svg";
import youtube from "../assets/img/brand/youtube.svg";
import plusIcon from "../assets/img/plus.svg";
import { getEditorId } from "store/actions/authAction";

const NewProject = () => {
  // Actions
  const dispatch = useDispatch();
  const history = useHistory();

  // InitialStates
  const initialFormData = {
    titel: "",
    videoPlatform: "",
    videoLange: "",
    untertitel: "",
    musik: "",
    prioritierung: "",
    anmerkung: "",
    videoFiles: [],
  };

  // useSelector
  const { uid, user } = useSelector((state) => state.authUser);
  const { loading, videoUploadingProgress } = useSelector(
    (state) => state.addProject
  );

  // States
  const [formData, setFormData] = useState(initialFormData);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [isloader, setIsloader] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [radioOptionsResetKey, setRadioOptionsResetKey] = useState(0);
  const [bill, setBill] = useState(0);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [paymentID, setPaymentID] = useState("");
  // States for the checkboxes
  const [termsChecked, setTermsChecked] = useState(false);
  const [projectAccepted, setProjectAccepted] = useState(false);

  // Funtions Start
  const handleVideoChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length > 0) {
      const reader = new FileReader();
      const previewVideos = files.map((file) => ({
        file: file,
        previewURL: URL.createObjectURL(file),
      }));
      setSelectedVideos((curr) => [...curr, ...previewVideos]);
      setFormData((curr) => ({
        ...curr,
        videoFiles: [...curr.videoFiles, ...files],
      }));
      setFileSelected(true);
    } else {
      alert("Ungültiges Dateiformat. Bitte laden Sie ein MP4-Video hoch.");
    }
  };

  const handleRemoveVideo = (index) => {
    // console.log("fileName: ", index);
    let mydata = selectedVideos.filter((video, idx) => idx !== index);
    // setSelectedVideos(mydata);
    // setSelectedVideos([]);

    // setSelectedVideos((prevVideos) =>
    //   prevVideos.filter((video) => video.file.name !== fileName)
    // );
    setIsloader(true);
    setTimeout(() => {
      setSelectedVideos(mydata);
      setIsloader(false);
    }, 300);

    setFormData((prevFormData) => ({
      ...prevFormData,
      videoFiles: prevFormData.videoFiles.filter((idx) => idx !== index),
    }));
  };

  // const handleFileUpload = (e) => {
  //   const files = Array.from(e.target.files);

  //   if (files.length > 0) {
  //     setFormData((curr) => ({ ...curr, videoFiles: [...curr.videoFiles, ...files] }));
  //     setFileSelected(true);

  //     // Dispatch UPLOAD_FILES_START action
  //     dispatch({ type: UPLOAD_FILES_START , payload: files });
  //     files.forEach((file) => {
  //       dispatch(uploadFile(file));
  //     });
  //   }
  // };

  const handlePayment = (data) => {
    toast.success(
      `Ihre Zahlung in Höhe von ${data?.amount / 100} € war erfolgreich`
    );
    setPaymentID(data?.paymentID);
    handleSubmit("Payment");
    setPaymentSuccess(true);
  };

  const handleSubmit = async (type) => {
    try {
      const isFormDataValid =
        formData?.titel !== "" &&
        formData?.videoPlatform !== "" &&
        formData?.videoFiles?.length > 0 &&
        formData?.videoLange !== "" &&
        formData?.untertitel !== "" &&
        formData?.prioritierung !== "" &&
        formData?.anmerkung !== "";

      if (!isFormDataValid) {
        toast.error("Alle Felder außer Musik sind Pflichtfelder", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return;
      }
      const uploadToastId = toast.info("Video hochladen...", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: false,
      });
      let editorId = await dispatch(getEditorId());
      let payload = {
        annotation: formData?.anmerkung,
        music: formData?.musik,
        prioritization: formData?.prioritierung,
        title: formData?.titel,
        videoLength: formData?.videoLange,
        videoPlatform: formData?.videoPlatform,
        subtitle: formData?.untertitel,
        customerId: uid,
        editorId,
        paymentId: paymentID,
        videoFiles: formData?.videoFiles,
        status: "inprogress",
      };
      if (type == "noPay") {
        delete payload.paymentId;
      }
      await dispatch(
        addProject(payload, () => {
          setPaymentSuccess(false);
          setPaymentID("");
          setFormData(initialFormData);
          setSelectedVideos([]);
          setRadioOptionsResetKey((prevKey) => prevKey + 1);
          toast.dismiss(uploadToastId);
          toast.success("Projekt erfolgreich hinzugefügt!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setTimeout(() => {
            history.push("/admin/projects");
          }, 2000);
        })
      );
    } catch (error) {
      console.log("Check Form", error);
    }
  };

  // Function to handle checkbox changes Start

  const handleTermsCheckbox = () => {
    setTermsChecked(!termsChecked);
  };

  const handleProjectAcceptCheckbox = () => {
    setProjectAccepted(!projectAccepted);
  };

  const checkSubmitEnable = () => {
    return termsChecked && projectAccepted;
  };

  // Function to handle checkbox changes End

  // useEffect

  useEffect(() => {
    setUploadProgress(videoUploadingProgress);
  }, [videoUploadingProgress]);

  useEffect(() => {
    const calculateBill = () => {
      let newBill = 0;
      if (formData.videoFiles.length > 0) {
        newBill += 99.95;
      }
      if (formData.prioritierung === "24h priorisierung") {
        newBill += 29.95;
      }
      if (formData.untertitel !== "Keinen" && formData.untertitel !== "") {
        newBill += 14.95;
      }
      setBill(newBill);
    };
    calculateBill();
  }, [formData]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card className="mb-5">
              <CardHeader>
                <div className="mt--5">
                  <HeadingCard title="Neues Projekt" m="ms-auto" />
                </div>
              </CardHeader>
              <CardBody className=" px-sm-5 px-3 pb-sm-5 pb-3 pt-sm-4 pt-3 ">
                <div>
                  {/* Titel */}
                  <div>
                    <HeadingCard title="Titel" className="text-center" />
                    <FormGroup className="mt-">
                      <label
                        className="form-control-label text-muted"
                        htmlFor="titel"
                      ></label>
                      <Input
                        className="form-control-alternative shadow border rounded"
                        id="titel"
                        type="text"
                        value={formData.titel}
                        required
                        onChange={(e) =>
                          setFormData({ ...formData, titel: e.target.value })
                        }
                      />
                    </FormGroup>
                  </div>
                  <hr className="hr" />
                  {/* Video Platform */}
                  <RadioOptions
                    title="Video Platform"
                    options={[
                      {
                        id: "Tiktok",
                        name: "videoPlatform",
                        imageUrl: tiktokIcon,
                      },
                      {
                        id: "Instagram",
                        name: "videoPlatform",
                        imageUrl: instagramIcon,
                      },
                      {
                        id: "Youtube Shorts",
                        name: "videoPlatform",
                        imageUrl: youtube,
                      },
                      {
                        id: "Andere",
                        name: "videoPlatform",
                        imageUrl: andereIcon,
                      },
                    ]}
                    onChange={(e) =>
                      setFormData((curr) => ({
                        ...curr,
                        videoPlatform: e.target.value,
                      }))
                    }
                    resetKey={radioOptionsResetKey}
                  />
                  <hr className="hr" />
                  {/* VideoLange */}
                  <RadioOptions
                    title="Video Länge"
                    options={[
                      {
                        id: "30s",
                        name: "videoLange",
                        imageUrl: videolangeimage1,
                      },
                      {
                        id: "45s",
                        name: "videoLange",
                        imageUrl: videolangeimage2,
                      },
                      {
                        id: "60s",
                        name: "videoLange",
                        imageUrl: videolangeimage3,
                      },
                    ]}
                    onChange={(e) =>
                      setFormData((curr) => ({
                        ...curr,
                        videoLange: e.target.value,
                      }))
                    }
                    resetKey={radioOptionsResetKey}
                  />
                  <hr className="hr" />
                  {/* Untertitel */}
                  <RadioOptions
                    title="Untertitel"
                    options={[
                      {
                        id: "Keinen",
                        name: "Untertitel",
                        imageUrl: wrongIcon,
                      },
                      {
                        id: "Stil 1",
                        name: "Untertitel",
                        imageUrl: still1,
                      },
                      {
                        id: "Stil 2",
                        name: "Untertitel",
                        imageUrl: still2,
                      },
                    ]}
                    type="subtitles"
                    onChange={(e) =>
                      setFormData((curr) => ({
                        ...curr,
                        untertitel: e.target.value,
                      }))
                    }
                    resetKey={radioOptionsResetKey}
                  />
                  <hr className="hr" />
                  {/* Musik */}
                  <div>
                    <HeadingCard title="Musik" className="text-center" />
                    <p className="text-center mt-4">
                      Teile uns die gewünschte Musik für dein Video mit.
                    </p>
                    <p className="text-center">
                      Falls du keinen Musikwunsch äußerst, wird ein aktuell
                      angesagtes Lied der gewünschten Platform ausgewählt.
                    </p>
                    <FormGroup className="text-center mt-4">
                      <label
                        className="form-control-label text-muted"
                        htmlFor="musik_input_field"
                      >
                        Geben Sie hier den vollständigen Titel. Oder bestenfalls
                        einen Link der gewünschten Musik an:
                      </label>
                      <Input
                        className="form-control-alternative shadow mt-3 border rounded"
                        id="musik_input_field"
                        type="text"
                        value={formData.musik}
                        onChange={(e) =>
                          setFormData({ ...formData, musik: e.target.value })
                        }
                      />
                    </FormGroup>
                  </div>
                  <hr className="hr" />
                  {/* Priorisierung */}
                  <RadioOptions
                    title="Priorisierung"
                    options={[
                      {
                        id: "Keine priorisierung",
                        name: "Priorisierung",
                        imageUrl: wrongIcon,
                      },
                      {
                        id: "24h priorisierung",
                        name: "Priorisierung",
                        imageUrl: hourIcon,
                      },
                    ]}
                    onChange={(e) =>
                      setFormData((curr) => ({
                        ...curr,
                        prioritierung: e.target.value,
                      }))
                    }
                    resetKey={radioOptionsResetKey}
                  />
                  <hr className="hr" />
                  {/* Dateiupload */}
                  <div>
                    <HeadingCard title="Dateiupload" className="text-center" />
                  </div>
                  <div
                    className={`d-flex flex-wrap justify-content-lg-start justify-content-center my-3 ${
                      selectedVideos?.length >= 1 ? "mx-md-0 mx-auto" : ""
                    }`}
                  >
                    {selectedVideos?.map((video, index) => (
                      <div className="videoCard mr-3 mb-3 d-flex" key={index}>
                        <div className="d-flex flex-column shadow rounded px-2 justify-content-center position-relative w-100 align-items-center">
                          <Badge
                            color="danger"
                            className="position-absolute top-0 right-0 rounded-circle badge-remove py-1 cursor-pointer"
                            onClick={() => handleRemoveVideo(index)}
                          >
                            <span>x</span>
                          </Badge>
                          {video.file.name.toLowerCase().endsWith(".mp4") ||
                          video.file.name.toLowerCase().endsWith(".mov") ||
                          video.file.name.toLowerCase().endsWith(".mkv") ? (
                            <>
                              {isloader ? (
                                <Spinner size="sm" />
                              ) : (
                                <video
                                  controls
                                  className="w-100 py-2 custom_widthHeight"
                                >
                                  <source
                                    src={`${video.previewURL}#t=0.001`}
                                    id="video-source"
                                    type="video/mp4"
                                  ></source>
                                </video>
                              )}
                            </>
                          ) : (
                            <img
                              src={video.previewURL}
                              alt="preview"
                              className="py-2 custom_widthHeight custom_widthHeight_Img"
                            />
                          )}
                          <Progress
                            value={uploadProgress}
                            animated
                            className={`${
                              uploadProgress == 0 || uploadProgress == 100
                                ? "d-none"
                                : "w-100"
                            }`}
                            color="success"
                          />
                        </div>
                      </div>
                    ))}
                    {/* {selectedVideos} */}
                    <div
                      className={`videoCard pl-0 d-flex ${
                        selectedVideos?.length == 0
                          ? "mx-auto pb-0 pr-0"
                          : "mr-3 mb-3"
                      }`}
                    >
                      <div
                        className={`${
                          selectedVideos?.length == 15 ? "d-none" : ""
                        } shadow rounded p-4`}
                      >
                        <label htmlFor="uploadFile" className="d-flex">
                          <img
                            src={
                              selectedVideos?.length >= 1
                                ? plusIcon
                                : uploadIcon
                            }
                            alt="upload"
                            className="w-75 mx-auto cursor-pointer"
                          />
                        </label>
                        <input
                          type="file"
                          // accept="video/mp4"
                          id="uploadFile"
                          name="uploadFile"
                          required
                          multiple
                          style={{ display: "none" }}
                          onChange={handleVideoChange}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="mt-3 d-flex align-items-center cursor-pointer">
                    {selectedVideos?.map((video) => (
                      <div className="d-flex flex-wrap mx-2">
                        <div
                          key={video.file.name}
                          className="mb-3 d-flex flex-column"
                        >
                          <video width="200px" height="160px" controls className="">
                            <source src={video.previewURL} type="video/mp4" />
                          </video>
                          <Progress
                            value={uploadProgress}
                            animated
                            className="mt-3"
                            color="success"
                          />
                        </div>
                      </div>
                    ))}
                    <div
                      className="shadow mb-2  mx-2 rounded p-4"
                      // style={{ width: "160px", height: "160px" }}
                    >
                      <label htmlFor="uploadFile" className="d-block m-auto">
                        <img
                          src={uploadIcon}
                          alt="upload"
                          className="d-block my-auto img-fluid "
                          style={{ width: "110px", height: "auto" }}
                        />
                      </label>
                      <input
                        type="file"
                        accept="video/mp4"
                        id="uploadFile"
                        name="uploadFile"
                        required
                        style={{ display: "none" }}
                        onChange={handleVideoChange}
                      />
                    </div>
                  </div> */}
                  {/* {fileSelected && (
                  )} */}
                  <hr className="hr" />
                  {/* Anmerkung */}
                  <div>
                    <HeadingCard title="Anmerkung" className="text-center" />
                    <FormGroup row>
                      <Col sm={12} className="mt-4">
                        <Input
                          type="textarea"
                          className="shadow border"
                          bsSize="lg"
                          rows="6"
                          required
                          value={formData.anmerkung}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              anmerkung: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                  </div>
                  <hr className="hr" />
                  {/* Bezahlart */}

                  {user?.payment && (
                    <HeadingCard title="Bezahlart" className="text-center" />
                  )}
                  <div className="flex flex-column text-center gap-5 mt-4">
                    <h4>
                      Ihre Rechnung für das aktuelle Projekt beträgt{" "}
                      {bill.toFixed(2)} €
                    </h4>

                    <div className="mb-3">
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          onChange={handleTermsCheckbox}
                          className="mt-2"
                        />{" "}
                        <Label check className="text-dark">
                          <small>
                            Ich habe die{" "}
                            <a href="/public/agbs" target="_blank">
                              AGBs
                            </a>{" "}
                            gelesen and akzeptiere diese{" "}
                          </small>
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          onChange={handleProjectAcceptCheckbox}
                          className="mt-2"
                        />{" "}
                        <Label check className="text-dark">
                          <small>
                            Ich akzeptiere das mir dieses Projekt in Rechnung
                            gestellt wird
                          </small>
                        </Label>
                      </FormGroup>
                    </div>

                    {user?.payment ? (
                      <>
                        <CreditCard
                          handlePayment={handlePayment}
                          bill={bill}
                          formData={formData}
                        />
                      </>
                    ) : (
                      <Button
                        type="button"
                        onClick={() => handleSubmit("noPay")}
                        color="success"
                        className="d-block btn-lg mx-auto px-5"
                        disabled={!checkSubmitEnable() || loading}
                      >
                        {loading ? <Spinner size="sm" /> : "Einreichen"}
                      </Button>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer autoClose={5000} />
    </>
  );
};

export default NewProject;
