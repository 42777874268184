import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAUh8uqDns-_r1IfY3qdz5LCE-WEDaK6o8",
  authDomain: "republish-maps.firebaseapp.com",
  projectId: "republish-maps",
  storageBucket: "republish-maps.appspot.com",
  messagingSenderId: "906804921728",
  appId: "1:906804921728:web:c6731f29e05f1095ed6d65",
  measurementId: "G-GH7Y8S2MXS"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);


