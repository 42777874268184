import OnlyHeader from "components/Headers/OnlyHeader";
import HeadingCard from "components/HeadingCard";
import CustomerTable from "components/Tables/CustomerTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  fetchAllCustomers,
  fetchMoreCustomers, fetchUpdateRequest
} from "store/actions/customerAction";

const CustomerRequests = () => {
  const dispatch = useDispatch();
  const { allCustomers, loading, loader } = useSelector(
    (state) => state.usersData
  );
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const [customerNumber, setCustomerNumber] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  console.log("selectedCustomer: ", selectedCustomer);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const handleApproved = (user) => {
    console.log("user: ", user);
    setSelectedCustomer(user);
    toggle();
  };

  const handleSave = (event) => {
    event.preventDefault();
    if (selectedCustomer) {
      dispatch(
        fetchUpdateRequest("approved", selectedCustomer?.id, customerNumber)
      );
    }
    toggle();
    setCustomerNumber("");
    setSelectedCustomer(null);
  };

  const handleRejected = (user) => {
    console.log('user: ', user);
    dispatch(fetchUpdateRequest("rejected", user?.id, customerNumber));
  };

  const handleLoadMore = () => {
    const lastItem = allCustomers[allCustomers?.length - 1];
    dispatch(
      fetchMoreCustomers("pending", lastItem.id, () => {
        setDisableLoadMore(true);
      })
    );
  };

  useEffect(() => {
    dispatch(fetchAllCustomers("pending"));
  }, [dispatch]);

  return (
    <div>
      <OnlyHeader />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col mb-4">
            <div className="mb--4">
              <HeadingCard title="Kundenwünsche" />
            </div>

            <Card className="shadow">
              {loader ? (
                <div className="mx-auto my-5">
                  <Spinner />
                </div>
              ) : (
                <CustomerTable
                  handleApproved={handleApproved}
                  handleRejected={handleRejected}
                  allCustomers={allCustomers}
                />
              )}
              {allCustomers?.length !== 0 && !disableLoadMore && (
                <Row className="justify-content-start align-items-center m-3">
                  <Button color="success" size="sm" onClick={handleLoadMore}>
                    {loading ? <Spinner size="sm" /> : "Mehr laden"}
                  </Button>
                </Row>
              )}
            </Card>
          </div>

          {/* Approved Modal ---Start  */}
          <Modal isOpen={modal} toggle={toggle} centered>
            <ModalHeader toggle={toggle} className="pb-0"></ModalHeader>
            <Container>
              <Form className="mx-4 mb-4" onSubmit={handleSave}>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label for="customerNumber">Kundennummer</Label>
                      <Input
                        id="customerNumber"
                        name="customerNumber"
                        type="text"
                        value={customerNumber}
                        onChange={(e) => setCustomerNumber(e.target.value)}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button
                    className="mt-4"
                    color="success"
                    size="md"
                    type="submit"
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Container>
          </Modal>
          {/* Approved Modal ---End  */}
        </Row>
      </Container>
    </div>
  );
};

export default CustomerRequests;
