/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Customers from "views/Customers";
import Index from "views/Index.js";
import NewProject from "views/NewProject";
import Notifications from "views/Notifications";
import Profile from "views/Profile.js";
import Projects from "views/Projects";
import ForgetPassword from "views/auth/ForgetPassword";
import Login from "views/auth/Login.js";
import Register from "views/auth/Register.js";
import icon1 from "../src/assets/img/dashboardIcon.png";
import icon3 from "../src/assets/img/newprojectIcon.png";
import icon4 from "../src/assets/img/notifyIcon.png";
import icon5 from "../src/assets/img/profileIcon.png";
import icon2 from "../src/assets/img/projectIcon.png";
import ProjectDetails from "./components/ProjectDetails";
import Help from "./views/auth/Help";
import CustomerRequests from "./views/CustomerRequests";
import Agbs from "views/Agbs";
import Impressum from "views/Impressum";
import Datenschutz from "views/Datenschutz";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: icon1,
    component: Index,
    layout: "/admin",
    roles: ["customer", "editor"],
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: Icons,
  //   layout: "/admin",
  // },
  // {
  // 	path: '/maps',
  // 	name: 'Maps',
  // 	icon: 'ni ni-pin-3 text-orange',
  // 	component: Maps,
  // 	layout: '/admin',
  // },
  // {
  // 	path: '/tables',
  // 	name: 'Tables',
  // 	icon: 'ni ni-bullet-list-67 text-red',
  // 	component: Tables,
  // 	layout: '/admin',
  // },
  {
    path: "/projects",
    name: "Projekte",
    icon: icon2,
    component: Projects,
    layout: "/admin",
    roles: ["customer", "editor", "admin"],
  },
  {
    path: "/new project",
    name: "Neues Projekt",
    icon: icon3,
    component: NewProject,
    layout: "/admin",
    roles: ["customer"],
  },
  {
    path: "/customers",
    name: "Kunden",
    icon: icon2,
    component: Customers,
    layout: "/admin",
    roles: ["admin"],
  },
  {
    path: "/customer-requests",
    name: "Kundenwünsche",
    icon: icon2,
    component: CustomerRequests,
    layout: "/admin",
    roles: ["admin"],
  },
  {
    path: "/notifications",
    name: "Benachrichtigungen",
    icon: icon4,
    component: Notifications,
    layout: "/admin",
    roles: ["customer", "editor"],
  },
  {
    path: "/user-profile",
    name: "Profil",
    icon: icon5,
    component: Profile,
    layout: "/admin",
    roles: ["customer", "editor"],
  },
  {
    path: "/help",
    name: "howToUse",
    icon: icon1,
    component: Help,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/agbs",
    name: "AGBs",
    component: Agbs,
    layout: "/public",
    isMenu: false,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: Impressum,
    layout: "/public",
    isMenu: false,
  },
  {
    path: "/datenschutz",
    name: "Datenschutz",
    component: Datenschutz,
    layout: "/public",
    isMenu: false,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25",
    component: Login,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08",
    component: Register,
    layout: "/auth",
    isMenu: false,
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    icon: "ni ni-circle-08",
    component: ForgetPassword,
    layout: "/auth",
    isMenu: false,
  },

  {
    path: "/project-detail/:id",
    name: "Project Details",
    icon: "fas fa-eye",
    component: ProjectDetails,
    layout: "/admin",
    isMenu: false,
  },
];
export default routes;
