/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import AuthHeader from "components/Headers/AuthHeader";
import { Container, Row, Col } from "reactstrap";
import routes from "routes.js";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthFooter from "../components/Footers/AuthFooter";
import AuthNavbar from "../components/Navbars/AuthNavbar";
const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();
  const history = useHistory();

  let { uid, user } = useSelector((state) => state.authUser);

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (uid) {
      if (user?.userType == "admin") {
        history.push("/admin/projects");
      } else {
        history.push("/admin/index");
      }
    }
  }, [uid]);

  return (
    <>
      <div className="bg-content" ref={mainContent}>
        {/* <div className=" pb-3 pb-lg-5 "> */}

        <Container>
          <AuthNavbar />
        </Container>

        {/* <Container> */}
        {/* <div className="header-body text-center pt-3 pt-lg-5 mb-7"> */}
        {/* <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Welcome!</h1>
                </Col>
              </Row> */}
        {/* </div> */}
        {/* </Container> */}
        {/* <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                // className="fill-dark"
                fill="#212529"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div> */}
        {/* </div> */}
        {/* Page content */}
        <Container>
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>

        <Container className="mb-3">
          <AuthFooter />
        </Container>
      </div>
    </>
  );
};

export default Auth;
