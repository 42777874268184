import HeadingCard from "components/HeadingCard";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Badge, Button, Card, Row, Spinner, Table } from "reactstrap";
import BclockImg from "../../assets/img/clock-b.png";
import WclockImg from "../../assets/img/clock-w.png";
import {
  fetchAllMoreProjects,
  fetchAllProjects,
  fetchMoreProjects,
  fetchProjectsById,
} from "../../store/actions/addProject";

const ProjTable = () => {

  // Actions
  const dispatch = useDispatch();
  const history = useHistory();

  // useSelectors
  const { projects, loading, loader } = useSelector((state) => state.project);
  const { user } = useSelector((state) => state.authUser);

  // States
  const [disableLoadMore, setDisableLoadMore] = useState(false);

  // Functions
  const handleLoadMore = () => {
    const lastItem = projects[projects?.length - 1];
    if (user?.userType === "customer" || user?.userType === "editor") {
      dispatch(
        fetchMoreProjects(user, lastItem, () => {
          setDisableLoadMore(true);
        })
      );
    } else {
      dispatch(
        fetchAllMoreProjects(lastItem, () => {
          setDisableLoadMore(true);
        })
      );
    }
  };

  // useEffect
  useEffect(() => {
    if (user?.userType === "customer" || user?.userType === "editor") {
      dispatch(fetchProjectsById(user));
    } else {
      dispatch(fetchAllProjects());
    }
  }, [dispatch, user]);

  return (
    <Row>
      <div className="col mb-4">
        <div className="mb--4">
          <HeadingCard title="Projekte" />
        </div>

        <Card className="shadow">
          {loader ? (
            <div className="mx-auto my-5">
              <Spinner />
            </div>
          ) : (
            <>
              {projects.length !== 0 ? (
                <Table className=" mt-5" responsive>
                  <thead className="bg-dark text-center">
                    <tr>
                      <th scope="col" className="font-weight-bolder text-white">
                        Projektnummer
                      </th>
                      <th scope="col" className="font-weight-bolder text-white">
                        TITEL
                      </th>
                      {user?.userType === "editor" ||
                      user?.userType === "admin" ? (
                        <>
                          <th
                            scope="col"
                            className="font-weight-bolder text-white"
                          >
                            Kundenname
                          </th>
                          <th
                            scope="col"
                            className="font-weight-bolder text-white"
                          >
                            Kundennummer
                          </th>
                        </>
                      ) : null}
                      <th scope="col" className="font-weight-bolder text-white">
                        PLATFORM
                      </th>
                      <th scope="col" className="font-weight-bolder text-white">
                        LÄNGE
                      </th>
                      <th scope="col" className="font-weight-bolder text-white">
                        UNTERTITEL
                      </th>
                      <th scope="col">
                        <img src={WclockImg} alt="clock" width={20} />
                      </th>
                      <th scope="col" className="font-weight-bolder text-white">
                        STATUS
                      </th>
                      <th scope="col" className="font-weight-bolder text-white">
                        DATUM
                      </th>
                      <th scope="col" className="font-weight-bolder text-white">
                        Aktion
                      </th>
                    </tr>
                  </thead>
                  {projects?.map((data, index) => {
                    return (
                      <tbody key={index} className=" text-center">
                        <tr>
                          <th scope="row" className="text-left">
                            {data?.projectNo}
                          </th>
                          <td className="text-left">{data?.title}</td>
                          {user?.userType === "editor" ||
                          user?.userType === "admin" ? (
                            <>
                              <td>{data?.customer?.fullname}</td>
                              <td>{data?.customer?.customerNumber}</td>
                            </>
                          ) : null}
                          <td>{data?.videoPlatform}</td>
                          <td>{data?.videoLength}</td>
                          <td>{data?.subtitle}</td>
                          <td>
                            {data?.prioritization === "24h priorisierung" && (
                              <img src={BclockImg} alt="clock" width={20} />
                            )}
                          </td>
                          <td className="">
                            {data?.status && (
                              <Badge
                                color={
                                  data.status === "inprogress"
                                    ? "primary"
                                    : data.status === "inrevision"
                                    ? "warning"
                                    : data.status === "completed"
                                    ? "success"
                                    : "none"
                                }
                              >
                                {data?.status}
                              </Badge>
                            )}
                          </td>
                          <td>
                            {data?.createdAt && (
                              <span>
                                {moment(data?.createdAt.toDate()).format(
                                  "DD.MM.YYYY HH:mm:ss"
                                )}
                              </span>
                            )}
                          </td>
                          <td className="text-center">
                            <i
                              class="fas fa-eye text-success"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                history.push(
                                  `/admin/project-detail/${data.id}`
                                );
                              }}
                            ></i>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
                </Table>
              ) : (
                <p className="mb-0 text-sm text-center mt-5 mb-4">
                  <b>Keine Daten gefunden</b>
                </p>
              )}

              {projects?.length !== 0 && !disableLoadMore && (
                <Row className="justify-content-start align-items-center m-3">
                  <Button color="success" size="sm" onClick={handleLoadMore}>
                    {loading ? <Spinner size="sm" /> : "Mehr laden"}
                  </Button>
                </Row>
              )}
            </>
          )}
        </Card>
      </div>
    </Row>
  );
};

export default ProjTable;
