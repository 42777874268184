import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { TEST_CLIENT_KEY } from "../Constants/index";
import { loadStripe } from "@stripe/stripe-js";
import { Col, Container, Row } from "reactstrap";
import StripeCheckout from "./StripeCheckout";
export default function CreditCard({ handlePayment, bill, formData }) {
  let stripePromise = loadStripe(TEST_CLIENT_KEY);
  return (
    <Container>
      <Row>
        <Col className="px-0">
          <Elements stripe={stripePromise}>
            <StripeCheckout
              handlePayment={handlePayment}
              bill={bill}
              formData={formData}
            />
          </Elements>
        </Col>
      </Row>
    </Container>
  );
}
