import OnlyHeader from "components/Headers/OnlyHeader";
import HeadingCard from "components/HeadingCard";
import CustomerDetailsModal from "components/Tables/CustomerDetailsModal";
import CustomerTable from "components/Tables/CustomerTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Container, Row, Spinner } from "reactstrap";
import { fetchAllCustomers, fetchMoreCustomers } from "store/actions/customerAction";

const Customers = () => {
  const dispatch = useDispatch();
  const { allCustomers, loading, loader } = useSelector(
    (state) => state.usersData
  );
  const [disableLoadMore, setDisableLoadMore] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const handleLoadMore = () => {
    const lastItem = allCustomers[allCustomers?.length - 1];
    dispatch(
      fetchMoreCustomers("approved", lastItem.id, () => {
        setDisableLoadMore(true);
      })
    );
  };

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    toggle();
  };

  useEffect(() => {
    dispatch(fetchAllCustomers("approved"));
  }, [dispatch]);

  return (
    <div>
      <OnlyHeader />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col mb-4">
            <div className="mb--4">
              <HeadingCard title="Kunden" />
            </div>

            <Card className="shadow">
              {loader ? (
                <div className="mx-auto my-5">
                  <Spinner />
                </div>
              ) : (
                <CustomerTable
                  handleViewDetails={handleViewDetails}
                  allCustomers={allCustomers}
                  hideRows={true}
                />
              )}
              {allCustomers?.length !== 0 && !disableLoadMore && (
                <Row className="justify-content-start align-items-center m-3">
                  <Button color="success" size="sm" onClick={handleLoadMore}>
                    {loading ? <Spinner size="sm" /> : "Mehr laden"}
                  </Button>
                </Row>
              )}
            </Card>
          </div>
          <CustomerDetailsModal
            modal={modal}
            toggle={toggle}
            user={selectedUser}
          />
        </Row>
      </Container>
    </div>
  );
};

export default Customers;
