import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  Row,
} from "reactstrap";
import { updatePaymentStatus } from "store/actions/customerAction";

function CustomerDetailsModal({ modal, toggle, user }) {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(user?.payment);
  const userId = user?.id;

  const handleCheckboxChange = (e) => {
    setChecked(e.target.checked);
    dispatch(updatePaymentStatus(userId, e.target.checked));
  };

  useEffect(() => {
    if (user) {
      setChecked(user?.payment);
    }
  }, [user]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle} className="pb-0"></ModalHeader>
        <Container>
          <Form className="mx-4 mb-4">
            <Row>
              <Col xs={6}>
                <FormGroup>
                  <Label for="fullName">Vollständiger Name</Label>
                  <Input
                    id="fullName"
                    name="fullName"
                    type="text"
                    value={user?.fullname}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="Email">Email</Label>
                  <Input
                    id="Email"
                    name="email"
                    type="email"
                    value={user?.email}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="phoneNumber">Telefonnummer</Label>
                  <Input
                    id="phoneNumber"
                    name="phoneNumber"
                    type="number"
                    value={user?.phoneNumber}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="address">Adresse</Label>
                  <Input
                    id="address"
                    name="address"
                    type="text"
                    value={user?.address}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="companyName">Name der Firma</Label>
                  <Input
                    id="companyName"
                    name="companyName"
                    type="text"
                    value={user?.companyName}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="videoCompleted">Videos abgeschlossen</Label>
                  <Input
                    id="videoCompleted"
                    name="videoCompleted"
                    type="number"
                    value={user?.videosCompleted}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="videoInProgress">Videos in Bearbeitung</Label>
                  <Input
                    id="videoInProgress"
                    name="videoInProgress"
                    type="number"
                    value={user?.videosInprogress}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={6}>
                <FormGroup>
                  <Label for="customerNumber">Kundennummer</Label>
                  <Input
                    id="customerNumber"
                    name="customerNumber"
                    type="text"
                    value={user?.customerNumber}
                    readOnly
                  />
                </FormGroup>
              </Col>
              <Col xs={12}>
                <FormGroup check>
                  <Input
                    id="PaymentCheck"
                    name="PaymentCheck"
                    type="checkbox"
                    checked={checked}
                    onChange={handleCheckboxChange}
                  />
                  <Label check for="PaymentCheck">
                    Zahlung aktiviert
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                className="mt-4"
                color="success"
                size="md"
                onClick={toggle}
              >
                Save
              </Button>
            </div>
          </Form>
        </Container>
      </Modal>
    </>
  );
}

export default CustomerDetailsModal;
