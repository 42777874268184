import React from "react";
import { FormGroup, Label } from "reactstrap";
import HeadingCard from "../HeadingCard";

const RadioOptions = ({
  title,
  options,
  value,
  onChange,
  error,
  resetKey,
  type,
}) => {
  return (
    <FormGroup key={resetKey}>
      <HeadingCard title={title} className="text-center">
        {title}
      </HeadingCard>

      <div className="mx-5 text-center mt-4 d-flex justify-content-center flex-column flex-sm-row flex-wrap">
        {options?.map((option) => (
          <div key={option.id}>
            {option.imageUrl && (
              <div className="px-4">
                <div>
                  <p className="h4 text-nowrap">{option?.id}</p>
                  <Label htmlFor={option.id}>
                    <img
                      src={option.imageUrl}
                      alt={`${option.id}`}
                      className="mb-3"
                      style={{
                        width: "100px",
                        minHeight: "100px",
                        height: "100px",
                        objectFit: type == "subtitles" ? "cover" : "",
                      }}
                    />
                  </Label>
                </div>
                <input
                  type="radio"
                  name={title}
                  id={option.id}
                  value={option.id}
                  onChange={onChange}
                  invalid={error && !value}
                  required
                  style={{ width: "18px", height: "18px" }}
                />
              </div>
            )}
          </div>
        ))}
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </FormGroup>
  );
};

export default RadioOptions;
