import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import OnlyHeader from "components/Headers/OnlyHeader";
import HeadingCard from "../components/HeadingCard";
import { updateUser } from "../store/actions/authAction";
import userProfileImage from "../assets/img/userprofileImage.jpg";
import avatar from "../assets/img/profileIcon.png";
import editProfileImageIcon from "../assets/img/editProfileImage.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Reactstrap imports
import {
  Card,
  CardHeader,
  CardBody,
  Form,
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";

const Profile = () => {
  const dispatch = useDispatch();
  const [isEditing, setIsEditing] = useState(false);
  const { user, uid } = useSelector((state) => state.authUser);
  // console.log("user: ", user);
  const [localImage, setLocalImage] = useState(null);
  const [userInput, setUserInput] = useState({
    profileImageFile: null,
    name: user?.fullname,
    email: user?.email,
    companyName: user?.companyName,
    customerNumber: user?.customerNumber,
    phoneNumber: user?.phoneNumber,
    address: user?.address,
    newPassword: "",
    uid: uid,
  });
  // console.log("userInput: ", userInput);

  const handleSwitchToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          setUserInput({
            ...userInput,
            // profileImage: URL.createObjectURL(file),
            profileImageFile: file,
            profileImageUrl: reader.result,
          });
        };
        setLocalImage(file);
        reader.readAsDataURL(file);
      } catch (error) {
        console.error("Fehler bei der Bildänderung:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch(
      updateUser(userInput, () => {
        setLocalImage(null);
        setIsEditing(false);
      })
    );
  };

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7 mb-3" fluid>
        <Row>
          <Col xs="12">
            <Card>
              <Form onSubmit={handleSubmit}>
                <CardHeader>
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center">
                    {/* <Col xs="auto"> */}
                    <div className="mt--6">
                      <HeadingCard title="Edit Profil" />
                    </div>
                    <FormGroup className="mb-0 mt-4 mt-md-0">
                      <Label for="editSwitch" className="mb-0">
                        Edit Profil
                      </Label>
                      <CustomInput
                        type="switch"
                        id="editSwitch"
                        name="editSwitch"
                        checked={isEditing}
                        onChange={handleSwitchToggle}
                        className="float-right ml-2 mr--4 cursor"
                      />
                    </FormGroup>
                  </div>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Row>
                      <Col
                        xs="12"
                        className="mt-4 mb-5 d-flex justify-content-center"
                      >
                        <label
                          htmlFor="profileImageInput"
                          className="d-block position-relative cursor-pointer profile"
                        >
                          <img
                            src={
                              localImage !== null
                                ? URL.createObjectURL(localImage)
                                : user?.profileImage
                                ? user?.profileImage
                                : avatar
                            }
                            alt="user profile"
                            className="d-block my-auto img-fluid rounded-circle profileImg bg-dark"
                          />
                          {isEditing && (
                            <div
                              style={{
                                position: "absolute",
                                bottom: 0,
                                right: 0,
                                background: "rgba(255, 255, 255, 0)",
                                padding: "4px",
                                cursor: "pointer",
                              }}
                            >
                              <img src={editProfileImageIcon} alt="edit icon" />
                            </div>
                          )}
                        </label>
                        <input
                          type="file"
                          id="profileImageInput"
                          accept="image/*"
                          disabled={!isEditing}
                          style={{ display: "none" }}
                          onChange={(e) => handleImageChange(e)}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="4" className="align-items-center">
                        <Label
                          for="name"
                          className="form-control-label text-muted mb-0"
                        >
                          Name
                        </Label>
                      </Col>
                      <Col md="8">
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          disabled={!isEditing}
                          value={userInput.name}
                          onChange={(e) =>
                            setUserInput({ ...userInput, name: e.target.value })
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="4" className="align-items-center">
                        <Label
                          for="username"
                          className="form-control-label text-muted mb-0"
                        >
                          Email
                        </Label>
                      </Col>
                      <Col md="8">
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          disabled
                          value={userInput.email}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="4" className="align-items-center">
                        <Label
                          for="companyName"
                          className="form-control-label text-muted mb-0"
                        >
                          Name der Firma
                        </Label>
                      </Col>
                      <Col md="8">
                        <Input
                          type="text"
                          name="companyName"
                          id="companyName"
                          disabled={!isEditing}
                          value={userInput.companyName}
                          onChange={(e) =>
                            setUserInput({
                              ...userInput,
                              companyName: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    {/* <Row>
                      <Col md="4" className="align-items-center">
                        <Label
                          for="customerNumber"
                          className="form-control-label text-muted mb-0"
                        >
                          Customer Number
                        </Label>
                      </Col>
                      <Col md="8">
                        <Input
                          type="number"
                          name="customerNumber"
                          id="customerNumber"
                          disabled={!isEditing}
                          value={userInput.customerNumber}
                          onChange={(e) =>
                            setUserInput({ ...userInput, customerNumber: e.target.value })
                          }
                        />
                      </Col>
                    </Row> */}
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="4" className="align-items-center">
                        <Label
                          for="phoneNumber"
                          className="form-control-label text-muted mb-0"
                        >
                          Telefonnummer
                        </Label>
                      </Col>
                      <Col md="8">
                        <Input
                          type="number"
                          name="phoneNumber"
                          id="phoneNumber"
                          disabled={!isEditing}
                          value={userInput.phoneNumber}
                          onChange={(e) =>
                            setUserInput({
                              ...userInput,
                              phoneNumber: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="4" className="align-items-center">
                        <Label
                          for="address"
                          className="form-control-label text-muted mb-0"
                        >
                          Adresse
                        </Label>
                      </Col>
                      <Col md="8">
                        <Input
                          type="text"
                          name="address"
                          id="address"
                          disabled={!isEditing}
                          value={userInput.address}
                          onChange={(e) =>
                            setUserInput({
                              ...userInput,
                              address: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col md="4" className="align-items-center">
                        <Label
                          for="password"
                          className="form-control-label text-muted mb-0"
                        >
                          Passwort
                        </Label>
                      </Col>
                      <Col md="8">
                        <Input
                          type="password"
                          name="password"
                          id="password"
                          placeholder="********"
                          disabled={!isEditing}
                          value={userInput.newPassword}
                          onChange={(e) =>
                            setUserInput({
                              ...userInput,
                              newPassword: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  <Button
                    color="success"
                    className={`mt-4 ${isEditing ? "" : "d-none"}`}
                  >
                    Änderungen speichern
                  </Button>
                </CardBody>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
      <ToastContainer limit={1} />
    </>
  );
};
export default Profile;
