import {
    Card,
    CardBody,
    Col,
} from "reactstrap";

const Help = () => {
    return (
        <Col lg="12" md="8">
            <Card
                className="bg-secondary shadow border-0 overflow-auto"
                style={{ height: '460px' }}
            >
                <CardBody className="px-lg-5 py-lg-5">
                    <h1>How to use</h1>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, Sapien nec sagittis aliquam malesuada bibendum. Nunc non blandit massa enim. Aliquam vestibulum morbi blandit cursus risus at ultrices. Facilisis leo vel fringilla est ullamcorper eget nulla. Tempus iaculis urna id volutpat lacus laoreet non curabitur.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur ipsum dolor sit. Bibendum est ultricies integer quis auctor elit sed vulputate mi. Neque laoreet suspendisse interdum consectetur libero id. Diam quis enim lobortis scelerisque fermentum dui faucibus in. Elit sed vulputate mi sit amet. Dictum at tempor commodo ullamcorper a lacus vestibulum. Quis blandit turpis cursus in hac. Nunc non blandit massa enim. Aliquam vestibulum morbi blandit cursus risus at ultrices. Facilisis leo vel fringilla est ullamcorper eget nulla. Tempus iaculis urna id volutpat lacus laoreet non curabitur.</p>
                </CardBody>
            </Card>
        </Col>
    )
}

export default Help