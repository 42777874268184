import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  ElementsConsumer,
  useStripe
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Spinner
} from "reactstrap";
import { TEST_CLIENT_KEY } from "../Constants/index";

import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

let stripePromise = loadStripe(TEST_CLIENT_KEY);

const StripeCheckout = ({ handlePayment, bill, formData }) => {
  const { uid } = useSelector((state) => state.authUser);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [stripeError, setStripeError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const stripe = useStripe();
  const handleStripError = (message) => {
    setStripeError(true);
    setErrorMessage(message);
    setTimeout(() => {
      setStripeError(false);
      setErrorMessage("");
    }, 3000);
  };

  return (
    <div>
      <Card className="shadow border-0 py-md-3 py-0 mt-3 ">
        <CardBody className="px-lg-5 px-3 pt-lg-2 pb-lg-2">
          <div className=" d-flex flex-column justify-content-center align-items-center">
            <h5 className="font-weight-bold">Bezahlen Sie mit Karte</h5>
          </div>
          <Elements stripe={stripePromise}>
            <ElementsConsumer>
              {({ elements, stripe }) => (
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    setLoader(true);
                    setPaymentLoader(true);
                    const isFormDataValid =
                    formData?.titel !== '' &&
                    formData?.videoPlatform !== '' &&
                    formData?.videoFiles?.length > 0 &&
                    formData?.videoLange !== "" &&
                    formData?.untertitel !== "" &&
                    formData?.prioritierung !== "" &&
                    formData?.anmerkung !== "" &&
                    true;

                    if (!isFormDataValid) {
                      toast.error("Alle Felder außer Musik sind Pflichtfelder", {
                        position: toast.POSITION.TOP_RIGHT,
                      });
                      setPaymentLoader(false);
                      setLoader(false);
                      return;
                    }
                    const cardElement = elements.getElement(CardNumberElement);
                    const cardElement2 = elements.getElement(CardExpiryElement);
                    const cardElement3 = elements.getElement(CardCvcElement);

                    let { error, paymentMethod } =
                      await stripe.createPaymentMethod({
                        type: "card",
                        card: cardElement,
                        card: cardElement2,
                        card: cardElement3,
                      });

                    if (error) {
                      handleStripError(error.message);
                      setPaymentLoader(false);
                      setLoader(false);
                    } else {
                      const result = await stripe.createToken(cardElement);

                      if (result.error) {
                        handleStripError(result.error.message);
                        setPaymentLoader(false);
                        setLoader(false);
                      } else {
                        // console.log(result.token.id, "result.token.id");
                        let body = {
                          tokenId: result.token.id,
                          userId: uid,
                          totalAmount: bill,
                          currency: "EUR",
                        };

                        await axios
                          .post(
                            "https://us-central1-republish-maps.cloudfunctions.net/app/api/payment/create-payment-intent",
                            body,
                            {
                              headers: { "Content-Type": "application/json" },
                            }
                          )
                          .then((response) => {
                            console.log(response, "Antwort");
                            console.log("Datensatz erfolgreich hinzugefügt!");
                            handlePayment({
                              paymentID: response?.data?.data?.id,
                              amount: response?.data?.data?.amount,
                            });
                            setPaymentLoader(true);
                            setLoader(false);
                          });
                      }
                    }
                  }}
                >
                  <>
                    <FormGroup>
                      <Label className="form_label">Kartennummer</Label>
                      <div
                        className="form-control mt-2 d-flex shadow-sm"
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <i className="fa fa-credit-card"></i>
                        <div
                          style={{
                            flexBasis: "90%",
                          }}
                        >
                          <CardNumberElement
                            required
                            options={{
                              placeholder: "XXXX XXXX XXXX XXXX",
                              style: {
                                base: {
                                  // backgroundColor: "#232733",
                                  fontSize: "16px",
                                },
                                invalid: {
                                  color: "#9e2146",
                                },
                              },
                            }}
                          />
                        </div>
                      </div>
                    </FormGroup>
                    <div className="row">
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="form_label">Verfallsdatum</Label>
                          <div
                            className="form-control mt-2 d-flex shadow-sm"
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <i className="fa fa-calendar"></i>
                            <div
                              style={{
                                flexBasis: "90%",
                              }}
                            >
                              <CardExpiryElement
                                required
                                options={{
                                  placeholder: "MM/YY",
                                  style: {
                                    base: {
                                      fontSize: "16px",
                                    },
                                    invalid: {
                                      color: "#9e2146",
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                      <div className="col-md-6">
                        <FormGroup>
                          <Label className="form_label">CVC/CVV</Label>
                          <div
                            className="form-control mt-2 d-flex shadow-sm"
                            style={{
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                flexBasis: "80%",
                              }}
                            >
                              <CardCvcElement
                                required
                                options={{
                                  placeholder: "...",
                                  style: {
                                    base: {
                                      fontSize: "16px",
                                    },
                                    invalid: {
                                      color: "#9e2146",
                                    },
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </FormGroup>
                      </div>
                    </div>
                  </>
                  {stripeError && (
                    <p className="mb-0 my-1 text-danger">{errorMessage}</p>
                  )}
                  <Button
                    size="lg"
                    className="btn btn-block"
                    color="success"
                    type="submit"
                    disabled={paymentLoader || bill == 0}
                  >
                    {loader ? <Spinner /> : "Bezahlen und los geht's!"}
                  </Button>
                </Form>
              )}
            </ElementsConsumer>
          </Elements>
        </CardBody>
      </Card>
    </div>
  );
};

export default StripeCheckout;
