import { NOTIFICATIONS_FETCH_REQUEST } from "store/types";
import { FETCH_MORE_NOTIFICATIONS_SUCCESS } from "store/types";
import {
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_SET_LOADING,
} from "store/types";

const initialState = {
  notifications: [],
  loading: false,
  loader: false,
  error: null,
};

export const notificationsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case NOTIFICATIONS_FETCH_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: payload,
      };
    case FETCH_MORE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: [...state.notifications, ...payload],
        loading: false,
      };
    case FETCH_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case NOTIFICATIONS_SET_LOADING:
      return {
        ...state,
        loader: payload,
      };

    default:
      return state;
  }
};
