import {
  VIDEO_UPLOAD_PROGRESS,
  ADD_PROJECT_LOADER,
  UPLOAD_FILES_START,
} from "store/types";

const initialState = {
  loading: false,
  videoUploadingProgress: [],
};

export default function addProjectReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ADD_PROJECT_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case UPLOAD_FILES_START: {
      return { ...state, uploadingFiles: payload };
    }
    case VIDEO_UPLOAD_PROGRESS:
      return {
        ...state,
        videoUploadingProgress: payload,
      };
    default:
      return {
        ...state,
      };
  }
}
