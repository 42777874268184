import {
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  updateDoc,
  where
} from "firebase/firestore";
import {
  FETCH_MORE_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE,
  FETCH_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_FETCH_REQUEST,
  NOTIFICATIONS_SET_LOADING,
} from "store/types";
import { db } from "../../config/firebase";

export const fetchNotifications = (authId) => async (dispatch) => {
  try {
    let fetchLimit = 10;
    dispatch(notificationsLoader(true));
    const notificationsCollection = query(collection(db, "notifications"));
    const UserData = query(
      notificationsCollection,
      where("userId", "==", authId),
      orderBy("createdAt", "desc"),
      limit(fetchLimit)
    );

    onSnapshot(UserData, async (querySnapshot) => {
      const notifications = [];
      querySnapshot.forEach((doc) => {
        notifications.push({
          id: doc.id,
          ...doc.data(),
        });
      });

      const projectsMapping = await fetchProjects();

      const notificationsWithProjectNo = notifications.map((notification) => ({
        ...notification,
        projectNo: projectsMapping[notification.projectId],
      }));
      
      // console.log('notificationsWithProjectNo: ', notificationsWithProjectNo);

      dispatch({
        type: FETCH_NOTIFICATIONS_SUCCESS,
        payload: notificationsWithProjectNo,
      });

      dispatch(notificationsLoader(false));
    });
  } catch (error) {
    dispatch({
      type: FETCH_NOTIFICATIONS_FAILURE,
      payload: error.message,
    });
    dispatch(notificationsLoader(false));
  }
};

export const fetchProjects = async () => {
  try {
    const projectsCollection = collection(db, "projects");
    const querySnapshot = await getDocs(projectsCollection);

    const projectsMapping = {};
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      projectsMapping[doc.id] = data.projectNo;
    });
    // console.log('projectsMapping: ', projectsMapping);
    return projectsMapping;
  } catch (error) {
    console.error("Error fetching projects:", error);
  }
};

export const fetchMoreNotifications =
  (authId, lastItem, onError = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: NOTIFICATIONS_FETCH_REQUEST,
    });
    try {
      let fetchLimit = 10;
      const notificationsCollection = query(collection(db, "notifications"));
      const UserData = query(
        notificationsCollection,
        where("userId", "==", authId),
        orderBy("createdAt", "desc"),
        startAfter(lastItem.createdAt),
        limit(fetchLimit)
      );

      const querySnapshot = await getDocs(UserData);
      const notifications = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (notifications?.length < fetchLimit) {
        onError();
      }
      
      const projectsMapping = await fetchProjects();

      const notificationsWithProjectNo = notifications.map((notification) => ({
        ...notification,
        projectNo: projectsMapping[notification.projectId],
      }));

      dispatch({
        type: FETCH_MORE_NOTIFICATIONS_SUCCESS,
        payload: notificationsWithProjectNo,
      });
    } catch (error) {
      dispatch({
        type: FETCH_NOTIFICATIONS_FAILURE,
        payload: error.message,
      });
    }
  };

export const updateNotificationAction = (id, Val) => async (dispatch) => {
  try {
    const updateDocument = doc(db, "notifications", id);
    const res = await updateDoc(updateDocument, {
      isRead: Val,
    });
  } catch (error) {}
};

// -------------Loader----------------
export const notificationsLoader = (value) => async (dispatch) => {
  dispatch({
    type: NOTIFICATIONS_SET_LOADING,
    payload: value,
  });
};
