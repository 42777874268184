import OnlyHeader from "components/Headers/OnlyHeader";
import NotifyTable from "components/Tables/NotifyTable";
import { Container } from "reactstrap";

const Notifications = () => {
  return (
    <div>
      <OnlyHeader />

      <Container className="mt--7" fluid>
        <NotifyTable />
      </Container>
    </div>
  )
};

export default Notifications;
