import { VIDEO_UPLOAD_PROGRESS, ADD_SUBMISSION_LOADER, UPLOAD_FILES_START, FETCH_SUBMISSION_SUCCESS  } from "store/types";

const initialState = {
  submission: [],
  loading: false,
  videoUploadingProgress:[],
};

export default function projectDetailsReducer(state = initialState, { type, payload }) {
  switch (type) {
    case ADD_SUBMISSION_LOADER:
      return {
        ...state,
        loading: payload,
      };
      case UPLOAD_FILES_START: {
        return { ...state, uploadingFiles: payload };
      }
    case VIDEO_UPLOAD_PROGRESS:
      return {
        ...state,
        videoUploadingProgress: payload,
      };
      case FETCH_SUBMISSION_SUCCESS:
        return {
          ...state,
          submission: payload,
        };
    default:
      return {
        ...state,
      };
  }
}
