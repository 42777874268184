/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  CardText,
  DropdownItem,
} from "reactstrap";
import notifyIcon from "../../assets/img/notifyIcon.png";
import finishVideoIcon from "../../assets/img/finishVideoIcon.png";
import inprogressVideoIcon from "../../assets/img/inprogressVideoIcon.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const headerCardsData = [
  {
    icon: notifyIcon,
    title: "NEUE BENACHRICHTIGUNGEN",
    text: "Alle Benachrichtigungen anzeigen",
    link: '/admin/notifications'
  },
  {
    icon: finishVideoIcon,
    title: "Fertiggestellte Videos",
    text: "Alle fertigen Videos anzeigen",
    link: '/admin/projects'
  },
  {
    icon: inprogressVideoIcon,
    title: "Videos in Bearbeitung",
    text: "Alle Videos in Bearbeitung anzeigen",
    link: '/admin/projects'
  },
];

const Header = () => {
  const { user } = useSelector((state) => state.authUser);

  return (
    <>
      <div className="header pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row className="justify-content-around">
              {headerCardsData?.map((data, index) => {
                return (
                  <Col lg="6" xl="3" key={index} className="mb-5">
                    <Card className="card-stats mb-1 mb-xl-0 h-100">
                      <CardBody className="pb-1 pt-1">
                        <Row>
                          <div className="d-flex flex-row w-100 justify-content-between">
                            <div className="pr-1 mt--4">
                              <div className="icon icon-shape text-white shadow bg-success w-100 h-100 px-2">
                                <img src={data.icon} width={55} />
                              </div>
                            </div>
                            <div className="d-flex flex-column mt-2 w-100">
                              <CardTitle
                                tag="h6"
                                className="text-uppercase text-muted mb-0 text-right"
                              >
                                {data.title}
                              </CardTitle>
                              <CardText className="h2 font-weight-bold mb-0 text-right">
                                {data?.title === "NEUE BENACHRICHTIGUNGEN" &&
                                  user?.notifications}
                                {data?.title === "Fertiggestellte Videos" &&
                                  user?.videosCompleted}
                                {data?.title === "Videos in Bearbeitung" &&
                                  user?.videosInprogress}
                              </CardText>
                            </div>
                          </div>
                        </Row>
                        <DropdownItem divider />

                        <Link to={data.link}>
                          <h6 className="text-muted mb-0 text-center mt-3">{data.text}</h6>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
