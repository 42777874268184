import {
  Timestamp,
  addDoc,
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import {
  ADD_SUBMISSION_LOADER,
  FETCH_SUBMISSION_FAILURE,
  FETCH_SUBMISSION_REQUEST,
  FETCH_SUBMISSION_SUCCESS,
  VIDEO_UPLOAD_PROGRESS,
} from "store/types";
import generateFileName from "utils/generateFileName";
import { db } from "../../config/firebase";

const updateExistingSubmissions = async (projectId) => {
  const submissionsRef = collection(db, "submissions");
  const querySnapshot = await getDocs(
    submissionsRef,
    where("projectId", "==", projectId)
  );
  const updatePromises = querySnapshot.docs.map(async (doc) => {
    const docRef = doc.ref;
    await updateDoc(docRef, { allowSubmissions: false });
  });
  await Promise.all(updatePromises);
};

export const addSubmission =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(loader(true));

      let videoFileUrls = [];

      if (payload.videoFileUrls && payload.videoFileUrls.length > 0) {
        videoFileUrls = await Promise.all(
          payload.videoFileUrls.map(async (videoFile) => {
            return await uploadFile(videoFile, dispatch);
          })
        );
        delete payload.videoFileUrls;
      }
      await updateExistingSubmissions(payload?.projectId);

      const submissionData = {
        ...payload,
        videoFileUrls,
        allowSubmissions: false,
        createdAt: Timestamp.now(),
      };

      await addDoc(collection(db, "submissions"), submissionData);

      dispatch(loader(false));
      onSuccess();
    } catch (error) {
      dispatch(loader(false));
      console.log("Fehler beim Hinzufügen der Einreichung: ", error);
      onSuccess();
    }
  };

export const getProjectSubmissions = (projectId) => async (dispatch) => {
  try {
    dispatch(loader(true));
    const submissionQuery = query(
      collection(db, "submissions"),
      where("projectId", "==", projectId),
      orderBy("createdAt", "asc")
    );
    onSnapshot(submissionQuery, (snapshot) => {
      const submission = [];
      for (let doc of snapshot?.docs) {
        if (doc.exists) {
          submission.push({ id: doc.id, ...doc.data() });
        }
      }
      dispatch({
        type: FETCH_SUBMISSION_SUCCESS,
        payload: submission,
      });
    });

    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    console.error("Fehler beim Abrufen der Einreichungen: ", error);
  }
};

export const updateSubmissionStatus =
  (submissionIds, status, comment) => async (dispatch) => {
    const moreSubmissions = status == "rejected" ? true : false;
    try {
      dispatch(loader(true));

      const submissionRef = doc(db, "submissions", submissionIds);
      await updateDoc(submissionRef, {
        status,
        comment,
        allowSubmissions: moreSubmissions,
      });

      dispatch(loader(false));
    } catch (error) {
      dispatch(loader(false));
      console.error(
        "Fehler beim Aktualisieren des Übermittlungsstatus: ",
        error
      );
    }
  };

export const loader = (value) => async (dispatch) => {
  dispatch({
    type: ADD_SUBMISSION_LOADER,
    payload: value,
  });
};

export const videoUploadingProgress = (value) => async (dispatch) => {
  dispatch({
    type: VIDEO_UPLOAD_PROGRESS,
    payload: value,
  });
};

export const uploadFile = async (file, dispatch) => {
  try {
    let fileName = generateFileName(file.name);
    const storage = getStorage();
    const storageReference = ref(storage, "files/" + fileName);
    const uploadTask = uploadBytesResumable(storageReference, file);
    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
          dispatch(videoUploadingProgress(progress));
        },
        (error) => {
          console.error("Fehler beim Hochladen der Datei", error);
          reject({ stage: "uploading", error });
        },
        async () => {
          // File uploaded successfully
          console.log("Datei erfolgreich hochgeladen");
          try {
            // Get the download URL
            const downloadURL = await getDownloadURL(storageReference);
            // console.log("Download URL:", downloadURL);
            resolve(downloadURL);
          } catch (error) {
            console.error("Error getting download URL", error);
            reject({ stage: "getting download URL", error });
          }
        }
      );
    });
  } catch (error) {
    console.error("Error in uploadFile function", error);
    throw error;
  }
};

export const fetchSubmission = () => async (dispatch) => {
  dispatch({
    type: FETCH_SUBMISSION_REQUEST,
    payload: null,
  });
  try {
    const submissionCollection = collection(db, "submissions");
    const querySnapshot = await getDocs(submissionCollection);
    const submission = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    // console.log("submission: ", submission);
    dispatch({
      type: FETCH_SUBMISSION_SUCCESS,
      payload: submission,
    });
  } catch (error) {
    // console.log("error: ", error);
    dispatch({
      type: FETCH_SUBMISSION_FAILURE,
      payload: error.message,
    });
  }
};
