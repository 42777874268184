/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { Link, useLocation } from "react-router-dom";
import socialsImg from "../../assets/img/logo_socials.png";
import avatar from "../../assets/img/profileIcon.png";
import { logout } from "store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import logout_b from "../../assets/img/logout_b.png";
import whatsapp_w from "../../assets/img/whatsapp_w.png";
import whatsapp_b from "../../assets/img/whatsapp_b.png";
import phone_w from "../../assets/img/phone_w.png";
import phone_b from "../../assets/img/phone_b.png";
import questionMark_w from "../../assets/img/questionMark_w.png";
import questionMark_b from "../../assets/img/questionMark_b.png";
import hoursIcon from "../../assets/img/hoursIcon.png";

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Row,
} from "reactstrap";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

const AdminNavbar = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.authUser);
  const location = useLocation();
  console.log("ROUTE", location.pathname);
  return (
    <>
      <Navbar
        className={`navbar-top navbar-dark   ${
          location.pathname == "/public/datenschutz" ||
          location.pathname == "/public/impressum" ||
          location.pathname == "/public/agbs"
            ? "d-flex flex-nowrap "
            : "d-none d-md-flex"
        }`}
        expand="md"
        id="navbar-main"
      >
        <div
          className={` ${
            location.pathname == "/public/datenschutz" ||
            location.pathname == "/public/impressum" ||
            location.pathname == "/public/agbs"
              ? "d-flex"
              : "d-none d-md-flex pb-2"
          }`}
        >
          <img src={socialsImg} alt="logo" width={70} />
        </div>
        <Container className="justify-content-end" style={{ gap: 20 }} fluid>
          {/* <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </Link>
          <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input placeholder="Search" type="text" />
              </InputGroup>
            </FormGroup>
          </Form> */}

          <img
            className="d-none d-lg-block cursor"
            src={hoursIcon}
            alt="48h"
            width={70}
          />

          <a href="tel:+491604550977" target="_blank">
            <img
              className="d-none d-lg-block cursor"
              src={phone_w}
              alt="phone"
              width={15}
            />
          </a>

          <a
            href="https://wa.me/+491604550977?text=Ich%20habe%20eine%20Frage%20zu%20der%20Socials%20Plattform."
            target="_blank"
          >
            <img
              className="d-none d-lg-block cursor"
              src={whatsapp_w}
              alt="whatsapp"
              width={15}
            />
          </a>

          <div className="d-none d-lg-flex flex-row align-items-center text-white cursor">
            <img src={questionMark_w} alt="?mark" width={15} />
            <small>ANLEITUNG</small>
          </div>

          <Nav
            className={`justify-content-end ${
              location.pathname == "/public/datenschutz" ||
              location.pathname == "/public/impressum" ||
              location.pathname == "/public/agbs"
                ? "d-flex"
                : "d-none d-md-flex"
            }`}
            navbar
          >
            <UncontrolledDropdown nav>
              <DropdownToggle className="p-0" nav>
                <Media className="align-items-center">
                  <div className="avatar avatar-sm rounded-circle bg-transparent">
                    <img
                      src={user?.profileImage ? user?.profileImage : avatar}
                      alt="avatar"
                    />
                  </div>

                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user?.fullname}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  className="noti-title d-md-flex d-lg-none"
                  header
                  tag="div"
                >
                  <h6 className="text-overflow m-0">{user?.fullname}</h6>
                </DropdownItem>
                <DropdownItem className="d-md-flex d-lg-none" divider />
                <DropdownItem className="d-md-flex d-lg-none" to="" tag={Link}>
                  <img
                    className="mr-2"
                    src={questionMark_b}
                    alt="?mark"
                    width={20}
                  />
                  <span>ANLEITUNG</span>
                </DropdownItem>
                <DropdownItem className="d-flex">
                  <a
                    href="https://wa.me/+491604550977?text=Ich%20habe%20eine%20Frage%20zu%20der%20Socials%20Plattform."
                    target="_blank"
                    className="text-dark"
                  >
                    <img
                      className="mr-2"
                      src={whatsapp_b}
                      alt="whatsapp"
                      width={20}
                    />
                    <span>WHATS APP</span>
                  </a>
                </DropdownItem>
                <DropdownItem className="d-md-flex d-lg-none">
                  <a
                    href="tel:+491604550977"
                    className="text-dark"
                    target="_blank"
                  >
                    <img
                      className="mr-2"
                      src={phone_b}
                      alt="phone"
                      width={20}
                    />
                    <span>CALL US</span>
                  </a>
                </DropdownItem>
                <DropdownItem
                  className="d-md-flex d-lg-none justify-content-center"
                  to=""
                  tag={Link}
                >
                  <img
                    style={{ cursor: "pointer" }}
                    src={hoursIcon}
                    alt="48h"
                    width={100}
                  />
                </DropdownItem>
                <DropdownItem className="d-md-flex d-lg-none" divider />
                <DropdownItem
                  onClick={() => {
                    localStorage.removeItem("auth");
                    dispatch(logout());
                  }}
                >
                  <img
                    className="mr-2"
                    src={logout_b}
                    alt="logout"
                    width={20}
                  />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
