
const initialState = {
  project: [],
  singleLoader: false,
  error: null,
};

const singleProjectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_SINGLE_LOADER":
      return {
        ...state,
        singleLoader: payload,
      };
    case "FETCH_SINGLE_PROJECT":
      return {
        ...state,
        project: payload,
      };
    case "CLEAR_SINGLE_PROJECT":
      return {
        ...state,
        project: [],
      };
    case "FETCH_SINGLE_ERROR":
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
};

export default singleProjectReducer;
