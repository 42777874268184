import { Card, CardBody, Col } from "reactstrap";

const Impressum = () => {
  return (
    <Col sm="10">
      <Card
        className="bg-dark shadow border-0 overflow-auto"
        style={{ height: "440px" }}
      >
        <CardBody className="px-lg-5 py-lg-5 text-white">
          <h1 className=" text-white">Impressum</h1>
          <p className="mb-4">
            Robin Landwehr
            <br />
            Straight Media <br />
            Mühlwehrstraße 28 <br />
            97980 Bad Mergentheim <br />
            Handelsregister: 56604
          </p>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Kontakt</h2>
            <p className="mb-0">
              Telefon:{" "}
              <a className="text-underline text-white" href="tel:+491604550977">
                +49 160 4550977
              </a>{" "}
              <br />
              E-Mail:{" "}
              <a
                className="text-underline text-white"
                href="mailto:robin@straight4media.de"
              >
                robin@straight4media.de
              </a>
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Umsatzsteuer-ID</h2>
            <p className="mb-0">
              Umsatzsteuer-Identifikationsnummer gemäß § 27 a
              Umsatzsteuergesetz: 80365/24500
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">Redaktionell verantwortlich</h2>
            <p className="mb-0">
              Robin Landwehr
              <br />
              Straight Media <br />
              Mühlwehrstraße 28 <br />
              97980 Bad Mergentheim
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">EU-Streitschlichtung</h2>
            <p className="mb-0">
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:{" "}
              <a
                className="text-underline text-white"
                href="https://ec.europa.eu/consumers/odr/"
                target="_blank"
              >
                https://ec.europa.eu/consumers/odr/
              </a>
              . Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>
          </div>
          <div className="mb-4">
            <h2 className="mb-0 text-white">
              Verbraucherstreitbeilegung/Universalschlichtungsstelle
            </h2>
            <p className="mb-0">
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </p>
          </div>
          <div className="pb-5">
            <h2 className="mb-0 text-white">
              Zentrale Kontaktstelle nach dem Digital Services Act - DSA
              (Verordnung (EU) 2022/265)
            </h2>
            <p className="mb-0">
              Unsere zentrale Kontaktstelle für Nutzer und Behörden nach Art.
              11, 12 DSA erreichen Sie wie folgt:
              <br />
              E-Mail:{" "}
              <a
                className="text-underline text-white"
                href="mailto:robin@straight4media.de"
              >
                robin@straight4media.de
              </a>{" "}
              <br />
              Telefon:{" "}
              <a className="text-underline text-white" href="tel:+491604550977">
                +49 160 4550977
              </a>
              <br />
              Die für den Kontakt zur Verfügung stehenden Sprachen sind:
              Deutsch, Englisch.
              <br />
              Quelle:
              <br />
              <a
                className="text-underline text-white"
                href="https://www.e-recht24.de/impressum-generator.html"
                target="_blank"
              >
                https://www.e-recht24.de/impressum-generator.html
              </a>
            </p>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Impressum;
